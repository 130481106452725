// @ts-strict-ignore
import actionTypes from 'src/modules/needsAssessment/needsAssessmentList/actionTypes';

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_NEEDS_ASSESSMENT_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_NEEDS_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        data: action.results ?? [],
        isLoading: false,
      };
    }
    case actionTypes.FETCH_NEEDS_ASSESSMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.DELETE_NEEDS_ASSESSMENT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(x => x.needsAssessmentId !== action.assessmentId),
      };
  }
  return state;
}
