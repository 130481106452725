export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';
export const FETCH_PROGRAM_FAILURE = 'FETCH_PROGRAM_FAILURE';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_FAILURE = 'FETCH_MODULES_FAILURE';
export const ENROLL_USER_SUCCESS = 'ENROLL_USER_SUCCESS';
export const ENROLL_USER_FAILURE = 'ENROLL_USER_FAILURE';
export const UNENROLL_USER_SUCCESS = 'UNENROLL_USER_SUCCESS';
export const UNENROLL_USER_FAILURE = 'UNENROLL_USER_FAILURE';
export const FETCH_CURRENT_MODULE_SUCCESS = 'FETCH_CURRENT_MODULE_SUCCESS';
export const FETCH_CURRENT_MODULE_FAILURE = 'FETCH_CURRENT_MODULE_FAILURE';
export const MARK_SECTION_AS_COMPLETE_SUCCESS = 'MARK_SECTION_AS_COMPLETE_SUCCESS';
export const MARK_SECTION_AS_COMPLETE_FAILURE = 'MARK_SECTION_AS_COMPLETE_FAILURE';
