import { EnrichedContent } from 'src/components/getElement';
import { Role } from 'src/constants/types';

export enum ActionType {
  FETCH_MESSAGES = 'FETCH_MESSAGES',
  FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS',
  FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE',

  HIDE_MESSAGE = 'HIDE_MESSAGE',
  HIDE_MESSAGE_SUCCESS = 'HIDE_MESSAGE_SUCCESS',
  HIDE_MESSAGE_FAILURE = 'HIDE_MESSAGE_FAILURE',

  SET_MESSAGE_COMPOSE_DETAILS = 'SET_MESSAGE_COMPOSE_DETAILS',

  FETCH_MESSAGE = 'FETCH_MESSAGE',
  FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS',
  FETCH_MESSAGE_FAILURE = 'FETCH_MESSAGE_FAILURE',

  FETCH_PREVIOUS_MESSAGES = 'FETCH_PREVIOUS_MESSAGES',
  FETCH_PREVIOUS_MESSAGES_SUCCESS = 'FETCH_PREVIOUS_MESSAGES_SUCCESS',
  FETCH_PREVIOUS_MESSAGES_FAILURE = 'FETCH_PREVIOUS_MESSAGES_FAILURE',

  MESSAGE_THREAD_ACTIVITY = 'MESSAGE_THREAD_ACTIVITY',

  MESSAGE_NEW = 'MESSAGE_NEW',
}

export type Action = {
  type: ActionType.FETCH_MESSAGES_SUCCESS;
  threads: ThreadItem[];
  pageNumber: number;
} | {
  type: ActionType.HIDE_MESSAGE_SUCCESS;
  id: number;
} | {
  type: ActionType.SET_MESSAGE_COMPOSE_DETAILS;
  participants: ComposeParticipant[];
  subject: string;
} | {
  type: ActionType.FETCH_MESSAGE_SUCCESS;
  thread: Thread;
} | {
  type: ActionType.FETCH_PREVIOUS_MESSAGES;
} | {
  type: ActionType.FETCH_PREVIOUS_MESSAGES_SUCCESS;
  messages: Message[];
} | {
  type: ActionType.FETCH_PREVIOUS_MESSAGES_FAILURE;
} | {
  type: ActionType.MESSAGE_THREAD_ACTIVITY;
  data: {
    isWriting: boolean;
    userId: number;
    threadId: number;
  };
} | {
  type: ActionType.MESSAGE_NEW;
  data: {
    id: number;
    author: { id: number };
    body: string;
    formattedBody: string;
    dateCreated: string;
    dateLastModified: string;
    parentId?: number;
    subject?: string;
  };
};

export type Messages = {
  threads: ThreadItem[]; // list of all message threads
  pageNumber: number;
  thread: Thread; // individual message thread
  isWritingList: {
    threadId: number;
    userId: number;
  }[];
}

export type ThreadItem = {
  body: string;
  community: {
    id: number;
    name: string;
    subDomain: string;
    logoImageMedium: string;
  };
  dateCreated: string;
  dateLastModified: string;
  hasRead: boolean;
  id: number;
  isDirectMessage: boolean;
  lastAuthorId: number;
  participants: ThreadItemParticipant[];
  subject: string;
};

type ThreadItemParticipant = {
  dateLeft: string;
  hasLeft: boolean;
  id: number;
  isDeleted: boolean;
  isMuted: boolean;
  profileImageSmall2x: string;
  username: string;
}

type Thread = {
  body?: string;
  isDirect: boolean | null;
  isLoading: boolean;
  activeParticipants?: number[];
  group: {
    id: number;
    name: string;
    subDomain: string;
    logoImageHash: string;
    isLearnMoreLinkEnabled: boolean;
    isPinnedPostsLinkEnabled: boolean;
    customWelcomeLink: {
      customLabel: string;
      customUrl: string;
    };
  } | null;
  messages: Message[];
  participants: {
    id: number;
    hasLeft?: boolean;
    profileImageSmall: string;
    roles?: Role[];
    username: string;
  }[];
  subject: string | null;
  threadId: number | null;
}
type Message = {
  id: number;
  subject?: string;
  body: EnrichedContent;
  dateCreated: string;
  dateLastModified: string;
  isDirect: boolean;
  author: {
    id: number;
    username: string;
    isDeleted: boolean;
    profileImageSmall: string;
    profileImageMedium: string;
    profileUrl: string;
  }
}

export type ComposeParticipant = {
  id: number;
  username: string;
  profileImageSmall: string;
}
