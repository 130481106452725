const actionTypes = {
  FETCH_NEEDS_ASSESSMENT_LIST: 'FETCH_NEEDS_ASSESSMENT_LIST',
  FETCH_NEEDS_ASSESSMENT_LIST_SUCCESS: 'FETCH_NEEDS_ASSESSMENT_LIST_SUCCESS',
  FETCH_NEEDS_ASSESSMENT_LIST_FAILURE: 'FETCH_NEEDS_ASSESSMENT_LIST_FAILURE',

  DELETE_NEEDS_ASSESSMENT: 'DELETE_NEEDS_ASSESSMENT',
  DELETE_NEEDS_ASSESSMENT_SUCCESS: 'DELETE_NEEDS_ASSESSMENT_SUCCESS',
  DELETE_NEEDS_ASSESSMENT_FAILURE: 'DELETE_NEEDS_ASSESSMENT_FAILURE',
};

export default actionTypes;
