const actionTypes = {
  EXCLUDE_TAG: 'EXCLUDE_TAG',

  IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE',

  WRITE_A_POST: 'WRITE_A_POST',
  WRITE_A_POST_SUCCESS: 'WRITE_A_POST_SUCCESS',
  WRITE_A_POST_FAILURE: 'WRITE_A_POST_FAILURE',

  FETCH_POST_EDIT: 'FETCH_POST_EDIT',
  FETCH_POST_EDIT_SUCCESS: 'FETCH_POST_EDIT_SUCCESS',
  FETCH_POST_EDIT_FAILURE: 'FETCH_POST_EDIT_FAILURE',

  FETCH_POLL_EDIT: 'FETCH_POLL_EDIT',
  FETCH_POLL_EDIT_SUCCESS: 'FETCH_POLL_EDIT_SUCCESS',
  FETCH_POLL_EDIT_FAILURE: 'FETCH_POLL_EDIT_FAILURE',

  EDIT_A_POST: 'EDIT_A_POST',
  EDIT_A_POST_SUCCESS: 'EDIT_A_POST_SUCCESS',
  EDIT_A_POST_FAILURE: 'EDIT_A_POST_FAILURE',

  SET_IS_EDITED: 'SET_IS_EDITED',
  SET_PRIVATE: 'SET_PRIVATE',
  SET_TAGS: 'SET_TAGS',
  SET_TOPIC: 'SET_TOPIC',
  SHOW_POLL_PAGE: 'SHOW_POLL_PAGE',

  RESET_WRITE_PAGE: 'RESET_WRITE_PAGE',
};

export default actionTypes;
