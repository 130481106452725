// @ts-strict-ignore
import { Action } from 'src/modules/shared/modal/components/signup/actions';
import contextActionTypes from 'src/modules/shared/context/actionTypes';

export type Community = {
  followerCount: number;
  logoUrl: string;
  name: string;
  slug: string;
}
export type SignupOnboarding = {
  communities: Community[];
  followedCommunities: Community[];
  isLoading: boolean;
  isNoResults: boolean;
}
export const initialState: SignupOnboarding = {
  communities: [],
  followedCommunities: [],
  isLoading: false,
  isNoResults: false,
};

export default function reducer(state = initialState, action): SignupOnboarding {
  switch (action.type) {
    case Action.UpdateCommunitySuggestions:
      return {
        ...state,
        isNoResults: false,
      };
    case Action.UpdateCommunitySuggestionsSuccess:
      return {
        ...state,
        isNoResults: false,
        communities: action.communities,
      };
    case Action.UpdateCommunitySuggestionsFailure:
      return {
        ...state,
        isNoResults: true,
        communities: [],
      };
    case Action.FollowCommunity:
      return {
        ...state,
        isLoading: true,
      };
    case Action.FollowCommunitySuccess:
      return {
        ...state,
        isLoading: false,
        followedCommunities: [...state.followedCommunities, action.community],
      };
    case Action.FollowCommunityFailure:
      return {
        ...state,
        isLoading: false,
      };
    case Action.UnfollowCommunitySuccess:
      return {
        ...state,
        followedCommunities: state.followedCommunities.filter(({ slug }) => slug !== action.communitySlug),
      };
    // We don't have an auth/reducer, hence we use this one for enabling/disabling the Join button in acceptTerms screen
    case contextActionTypes.SIGN_UP:
    case contextActionTypes.SOCIAL_SIGN_UP:
      return {
        ...state,
        isLoading: true,
      };
    case contextActionTypes.SIGN_UP_SUCCESS:
    case contextActionTypes.SOCIAL_SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case contextActionTypes.SIGN_UP_FAILURE:
    case contextActionTypes.SOCIAL_SIGN_UP_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
