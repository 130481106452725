// @ts-strict-ignore
import * as actionTypes from 'src/modules/community/poll/actionTypes';

type choice = { votes: number }

type poll = {
  choices: choice[]
}

type state = {
  post: null | poll;
  isLoading: boolean;
  postError: { status: number } | null;
  nextPosts: [];
  popularPosts: [];
  featuredPosts: [],
  dismissedFeaturedPostIds: [],
  categories: [],
  pinnedPosts: [],
  relatedPosts: [],
  freePostId: null | number,
  roles: null | [],
  isResults: boolean,
}

export const initialState: state = {
  post: null,
  isLoading: false,
  postError: null,
  nextPosts: [],
  popularPosts: [],
  featuredPosts: [],
  dismissedFeaturedPostIds: [],
  categories: [],
  pinnedPosts: [],
  relatedPosts: [],
  freePostId: null,
  roles: null,
  isResults: false,
};

const sortChoices = (choices: choice[]) => choices.sort((a, b) => b.votes - a.votes);

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.POLL_FETCH:
      return {
        ...state,
        isLoading: true,
        postError: null,
        relatedPosts: [],
      };
    case actionTypes.POLL_FETCH_SUCCESS: {
      let sortedChoices = action.post?.choices;
      if (!action.post.isVotingAllowed && action.post?.choices) {
        sortedChoices = sortChoices(action.post.choices);
      }
      return {
        ...state,
        post: {
          ...action.post,
          choices: sortedChoices || action.post.choices,
          highestVote: sortedChoices[0]?.votes,
        },
        isLoading: false,
        freePostId: state.freePostId || action.post.id,
      };
    }
    case actionTypes.POLL_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        postError: {
          status: action.error?.response?.status || 500,
        },
      };
    case actionTypes.POLL_RESULTS_SHOW: {
      const sortedChoices = state.post?.choices ? sortChoices(state.post.choices) : [];
      return {
        ...state,
        post: {
          ...(state.post && state.post),
          choices: sortedChoices,
          isResults: true,
          highestVote: sortedChoices[0]?.votes,
        },
      };
    }
    case actionTypes.POLL_RESULTS_HIDE:
      return {
        ...state,
        post: {
          ...(state.post && state.post),
          isResults: false,
        },
      };
  }
  return state;
}
