export enum Notification {
  NewPost = 10,
  NewPoll = 20,
  ResponsesToMyPost = 25,
  PrivateMessage = 35,
  DailyCommunityActivitySummary = 40,
  NewDailyCommunityActivitySummary = 41,
  WeeklyCommunityActivitySummary = 45,
  MonthlyCommunityActivitySummary = 46,
  HuAnnouncements = 50,
  HuResearchEmails = 55,
  FollowedUserActivity = 60,
  FollowedPostActivity = 65,
  UserMentions = 70,
  CanYouHelpX = 75,
  Programs = 80,
  CommunityInvitations = 90,
}
