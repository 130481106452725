const actionTypes = {
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',

  UPDATE_PROFILE_BASICS_SUCCESS: 'UPDATE_PROFILE_BASICS_SUCCESS',

  ADD_PROFILE_CONDITIONS_SUCCESS: 'ADD_PROFILE_CONDITIONS_SUCCESS',

  UPDATE_PROFILE_CONDITION_SUCCESS: 'UPDATE_PROFILE_CONDITION_SUCCESS',

  DELETE_PROFILE_CONDITION_SUCCESS: 'DELETE_PROFILE_CONDITION_SUCCESS',

  ADD_PROFILE_TREATMENT_SUCCESS: 'ADD_PROFILE_TREATMENT_SUCCESS',

  UPDATE_PROFILE_TREATMENT_SUCCESS: 'UPDATE_PROFILE_TREATMENT_SUCCESS',

  DELETE_PROFILE_TREATMENT_SUCCESS: 'DELETE_PROFILE_TREATMENT_SUCCESS',

  ADD_PROFILE_INTERESTS_SUCCESS: 'ADD_PROFILE_INTERESTS_SUCCESS',

  DELETE_PROFILE_INTEREST_SUCCESS: 'DELETE_PROFILE_INTEREST_SUCCESS',
};

export default actionTypes;
