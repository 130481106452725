// @ts-strict-ignore
import { Post } from 'src/constants/types';
import { parseString } from 'src/utils/parserText';

export function enrichDataObject(post, mentionedUsers): Post {

  const enriched = {
    body: parseString(post.body, mentionedUsers),
    snippet: parseString(post.snippet || post.bodySnippet, mentionedUsers),
  };

  if (enriched.body) {
    let limit = 140;
    enriched.snippet = JSON.parse(JSON.stringify(enriched.body))
      .filter(item => {
        if (limit < 0) {
          return false;
        }
        switch (item.type) {
          case 'mention':
            limit -= item.username.length;
            return true;
          case 'link': {
            const oldLimit = limit;
            limit -= item.text.length;
            return oldLimit < 80 || limit >= -20;
          }
          case 'text': {
            const newLimit = limit - item.text.length;
            item.text = item.text.slice(0, limit);
            limit = newLimit;
            return true;
          }
          case 'return':
          default:
            return true;
        }
      });
    if (limit < 0) {
      enriched.snippet.push({ type: 'text', text: '...', style: {} });
    }
  }

  return {
    ...post,
    enriched,
  };
}
