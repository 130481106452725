// @ts-strict-ignore
import actionTypes from 'src/modules/needsAssessment/needsAssessmentReport/actionTypes';
import { Card } from 'src/modules/shared/discover/components/discoverCard';

type State = {
  results: {
    answers: {
      answerValue: number,
      questionId: number,
    }[],
    communityNeeds: {
      questionId: number,
      answers: {
        answerValue: number,
        percentage: number,
      }[],
    }[],
    dateCreated?: string,
  },
  isLoading: boolean,
  error: null | string,
  recommendations: Card[],
  isLoadingRecommendations: boolean,
}

const initialState: State = {
  results: {
    answers: [],
    communityNeeds: [],
  },
  isLoading: true,
  error: null,
  recommendations: [],
  isLoadingRecommendations: false,
};

export default function reducer(state = initialState, action): State {
  switch (action.type) {
    case actionTypes.FETCH_NEEDS_ASSESSMENT_RESULTS:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_NEEDS_ASSESSMENT_RESULTS_SUCCESS: {
      const { results = {} } = action;
      return {
        ...state,
        results,
        isLoading: false,
      };
    }
    case actionTypes.FETCH_NEEDS_ASSESSMENT_RESULTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS:
      return {
        ...state,
        isLoadingRecommendations: true,
      };
    case actionTypes.FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendations: action.results,
        isLoadingRecommendations: false,
      };
    case actionTypes.FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        isLoadingRecommendations: false,
      };
  }
  return state;
}
