const actionTypes = {
  FETCH_COMMUNITY_POSTS_PAGE_POSTS: 'FETCH_COMMUNITY_POSTS_PAGE_POSTS',
  FETCH_COMMUNITY_POSTS_PAGE_POSTS_SUCCESS: 'FETCH_COMMUNITY_POSTS_PAGE_POSTS_SUCCESS',
  FETCH_COMMUNITY_POSTS_PAGE_POSTS_FAILURE: 'FETCH_COMMUNITY_POSTS_PAGE_POSTS_FAILURE',

  FETCH_COMMUNITY_TOP_TAGS: 'FETCH_COMMUNITY_TOP_TAGS',
  FETCH_COMMUNITY_TOP_TAGS_SUCCESS: 'FETCH_COMMUNITY_TOP_TAGS_SUCCESS',
  FETCH_COMMUNITY_TOP_TAGS_FAILURE: 'FETCH_COMMUNITY_TOP_TAGS_FAILURE',

  FETCH_POSTS_PINNED_POSTS: 'FETCH_POSTS_PINNED_POSTS',
  FETCH_POSTS_PINNED_POSTS_SUCCESS: 'FETCH_POSTS_PINNED_POSTS_SUCCESS',
  FETCH_POSTS_PINNED_POSTS_FAILURE: 'FETCH_POSTS_PINNED_POSTS_FAILURE',

  FETCH_ARCHIVE: 'FETCH_ARCHIVE',
  FETCH_ARCHIVE_SUCCESS: 'FETCH_ARCHIVE_SUCCESS',
  FETCH_ARCHIVE_FAILURE: 'FETCH_ARCHIVE_FAILURE',

  FETCH_TOTAL_MONTHLY_POSTS: 'FETCH_TOTAL_MONTHLY_POSTS',
  FETCH_TOTAL_MONTHLY_POSTS_SUCCESS: 'FETCH_TOTAL_MONTHLY_POSTS_SUCCESS',
  FETCH_TOTAL_MONTHLY_POSTS_FAILURE: 'FETCH_TOTAL_MONTHLY_POSTS_FAILURE',

  FETCH_LATEST_POLL: 'FETCH_LATEST_POLL',
  FETCH_LATEST_POLL_SUCCESS: 'FETCH_LATEST_POLL_SUCCESS',
  FETCH_LATEST_POLL_FAILURE: 'FETCH_LATEST_POLL_FAILURE',
};

export default actionTypes;
