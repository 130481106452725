const actionTypes = {
  FETCH_POST: 'FETCH_POST',
  FETCH_POST_SUCCESS: 'FETCH_POST_SUCCESS',
  FETCH_POST_FAILURE: 'FETCH_POST_FAILURE',

  FETCH_RELATED_POSTS: 'FETCH_RELATED_POSTS',
  FETCH_RELATED_POSTS_SUCCESS: 'FETCH_RELATED_POSTS_SUCCESS',
  FETCH_RELATED_POSTS_FAILURE: 'FETCH_RELATED_POSTS_FAILURE',

  FETCH_POPULAR_POSTS: 'FETCH_POPULAR_POSTS',
  FETCH_POPULAR_POSTS_SUCCESS: 'FETCH_POPULAR_POSTS_SUCCESS',
  FETCH_POPULAR_POSTS_FAILURE: 'FETCH_POPULAR_POSTS_FAILURE',

  FETCH_PINNED_POSTS: 'FETCH_PINNED_POSTS',
  FETCH_PINNED_POSTS_SUCCESS: 'FETCH_PINNED_POSTS_SUCCESS',
  FETCH_PINNED_POSTS_FAILURE: 'FETCH_PINNED_POSTS_FAILURE',

  FETCH_NEXT_POSTS: 'FETCH_NEXT_POSTS',
  FETCH_NEXT_POSTS_SUCCCESS: 'FETCH_NEXT_POSTS_SUCCCESS',
  FETCH_NEXT_POSTS_FAILURE: 'FETCH_NEXT_POSTS_FAILURE',

  UPDATE_POST_LOCK: 'UPDATE_POST_LOCK',
  UPDATE_POST_LOCK_SUCCESS: 'UPDATE_POST_LOCK_SUCCESS',
  UPDATE_POST_LOCK_FAILURE: 'UPDATE_POST_LOCK_FAILURE',

  UPDATE_POST_PIN: 'UPDATE_POST_PIN',
  UPDATE_POST_PIN_SUCCESS: 'UPDATE_POST_PIN_SUCCESS',
  UPDATE_POST_PIN_FAILURE: 'UPDATE_POST_PIN_FAILURE',

  DELETE_POST: 'DELETE_POST',
  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  DELETE_POST_FAILURE: 'DELETE_POST_FAILURE',

  RESTRICT_USER: 'RESTRICT_USER',
  RESTRICT_USER_SUCCESS: 'RESTRICT_USER_SUCCESS',
  RESTRICT_USER_FAILURE: 'RESTRICT_USER_FAILURE',

  REPORT_POST: 'REPORT_POST',
  REPORT_POST_SUCCESS: 'REPORT_POST_SUCCESS',
  REPORT_POST_FAILURE: 'REPORT_POST_FAILURE',

  LIKE_POST: 'LIKE_POST',
  LIKE_POST_SUCCESS: 'LIKE_POST_SUCCESS',
  LIKE_POST_FAILURE: 'LIKE_POST_FAILURE',

  FOLLOW_POST: 'FOLLOW_POST',
  FOLLOW_POST_SUCCESS: 'FOLLOW_POST_SUCCESS',
  FOLLOW_POST_FAILURE: 'FOLLOW_POST_FAILURE',

  UNFOLLOW_POST: 'UNFOLLOW_POST',
  UNFOLLOW_POST_SUCCESS: 'UNFOLLOW_POST_SUCCESS',
  UNFOLLOW_POST_FAILURE: 'UNFOLLOW_POST_FAILURE',

  REPLY_POST: 'REPLY_POST',
  REPLY_POST_SUCCESS: 'REPLY_POST_SUCCESS',
  REPLY_POST_FAILURE: 'REPLY_POST_FAILURE',

  REPLY_IMAGE_UPLOAD_SUCCESS: 'REPLY_IMAGE_UPLOAD_SUCCESS',
  REPLY_IMAGE_UPLOAD_FAILURE: 'REPLY_IMAGE_UPLOAD_FAILURE',

  UPDATE_POST_REPLY: 'UPDATE_POST_REPLY',
  UPDATE_POST_REPLY_SUCCESS: 'UPDATE_POST_REPLY_SUCCESS',
  UPDATE_POST_REPLY_FAILURE: 'UPDATE_POST_REPLY_FAILURE',

  DELETE_POST_REPLY: 'DELETE_POST_REPLY',
  DELETE_POST_REPLY_SUCCESS: 'DELETE_POST_REPLY_SUCCESS',
  DELETE_POST_REPLY_FAILURE: 'DELETE_POST_REPLY_FAILURE',

  SHOW_SECOND_PAGE_BLOCK_MESSAGE_BOX: 'SHOW_SECOND_PAGE_BLOCK_MESSAGE_BOX',
};

export default actionTypes;
