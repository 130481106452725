// @ts-strict-ignore
// Facebook Login SDK documentation: https://developers.facebook.com/docs/facebook-login/web
// https://developers.facebook.com/docs/javascript/reference/v15.0

import config from 'config/config';
import { trackEvent } from 'src/utils/analytics';

type LoginStatus = { authResponse?: { accessToken?: string, userID?: string }, status?: string };
let fbLoginStatus: LoginStatus = {};

export const init = () => {
  return new Promise((resolve) => {

    if (window.fbIsInit) {
      // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
      resolve();
      return;
    }

    const initSignup = () => {
      if (window.FB) {
        window.FB.init({
          appId: config.fb.appId,
          localStorage: false,
          version: 'v19.0',
          xfbml: true,
        });
        window.fbIsInit = true;
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      }
    };

    initSignup();

    // init() runs before the Cookie Control onAccept() when the user accepted cookies
    // and the page loads with login/sign up modals opened by default (thanks to url params)
    // so we use the asynchronous FB function, window.fbAsyncInit, for this scenario only.
    window.fbAsyncInit = () => initSignup();
  });
};

export const initFacebookSignup = (fbReady: (loginStatus: LoginStatus) => void = () => {}) => {
  init().then(() => {
    if (!window.fbIsInit) init();
    if (!window.FB) return;

    // Force the SDK to not cache the response with the second parameter set to true
    window.FB.getLoginStatus((loginStatus) => {
      fbLoginStatus = loginStatus;
      // TECH-279 We now initialize the SDK to not store access token in local storage
      // but we need to remove old FB keys to enable switching between different Facebook accounts.
      window.localStorage?.removeItem('fblst_542626299164413'); // number is our app id (config.fb.appId)
      fbReady(loginStatus);
    }, true);
  });
};

export const facebookSignupClick = (validateLoginData = () => {}) => {
  if (!window.FB) return;

  trackEvent('Account', 'Clicked Facebook login button', '');

  if (fbLoginStatus.status === 'connected') { // The user is logged into FB and has authorized our app
    handleLogin(fbLoginStatus, validateLoginData);
  } else {
    // Log in with the Javascript SDK Login Dialog - should only be called after a user click event
    window.FB.login(loginResponse => {
      // loginResponse.authResponse supplies the user's ID and a valid access token
      fbLoginStatus = loginResponse;
      handleLogin(loginResponse, validateLoginData);
    }, {
      // scope optional parameter is a list of permissions that a person must confirm to give our webpage access to their data.
      // Facebook Login requires advanced public_profile permission, to be used by external users.
      scope: config.fb.scope,
    });
  }
};

const handleLogin = (response, validateLoginData) => {
  if (response.status === 'connected') { // The user is logged into FB and has authorized our app
    window.FB.api('/me?fields=email', userInfo => generateAuthDataAndLoginWithFB(userInfo, validateLoginData));
  }
};

const generateAuthDataAndLoginWithFB = (userInfo, validateLoginData) => {
  if (!userInfo || userInfo.error) {
    // Expired or invalid access tokens. Token becomes invalid when a person logged out of our app or Facebook, or changed their password.
    // The user needs to login again to get a valid access token so we can make API calls on their behalf. Can't call FB.login() manually,
    // the popup window will be blocked by most browsers. Instead, we will show an error banner to ask them to click the Facebook button again.
    validateLoginData();
  } else {
    // Data sent to session/oauth endpoint
    const fbLoginData = {
      accessToken: fbLoginStatus.authResponse?.accessToken,
      facebookUserId: fbLoginStatus.authResponse?.userID,
      email: userInfo.email,
      isVerified: true,
    };
    validateLoginData(fbLoginData);
  }
};

export const onFacebookShareClick = () => {
  if (!window.fbIsInit) return;

  const options = {
    method: 'share',
    href: `https://healthunlocked.com${window.location.pathname}`,
  };
  if ('ontouchstart' in window) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'display' does not exist on type '{ metho... Remove this comment to see the full error message
    options.display = 'touch';
  }
  window.FB.ui(options);
};
