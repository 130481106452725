import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getServerSideConfig, post } from 'src/utils/api';
import { Integration } from 'src/modules/community/communityMetrics/types';
import { ReduxState } from 'src/store/store';

export const initialState: Integration = {
  gaTrackingId: null,
  status: 'idle',
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGATrackingId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGATrackingId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.gaTrackingId = action.payload.gaTrackingId;
      })
      .addCase(updateGATrackingId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateGATrackingId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.gaTrackingId = action.payload.gaTrackingId;
      });
  },
});

export default integrationSlice.reducer;

export const fetchGATrackingId = createAsyncThunk<{ gaTrackingId: string }, string, { state: ReduxState }>('integration/fetchGATrackingId', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/ga-tracking`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const updateGATrackingId = createAsyncThunk<{ gaTrackingId: string }, { slug: string, gaTrackingId: string }, { state: ReduxState }>('integration/updateGATrackingId', async ({ slug, gaTrackingId }, thunkApi) => {
  try {
    const { data } = await post(`private/communities/${slug}/ga-tracking`, { gaTrackingId: gaTrackingId || null });
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
