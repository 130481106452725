export type AlertType = 'success' | 'danger';

export enum ActionType {
  ALERT_SET = 'ALERT_SET',
  ALERT_DISMISS = 'ALERT_DISMISS',
}

export type Alert = {
  message: string | JSX.Element,
  type: AlertType,
  timeout?: number | 'none',
}

export type State = {
  alert: Alert | null,
}

export type Action = State & {
  type: ActionType;
}
