// @ts-strict-ignore
import { EMAIL } from 'src/constants/regex';

export const required = (customMessage?: string) => (value: string): undefined | string => (value
  ? undefined
  : customMessage || 'This field is required');

export const maxLength = (max: number, customMessage?: string) => (value: string): undefined | string =>
  value && value.length > max
    ? customMessage || `This field must must be ${max} characters or less`
    : undefined;

export const minLength = (min: number, customMessage?: string) => (value: string): undefined | string =>
  value && value.length < min
    ? customMessage || `This field must be ${min} characters or more`
    : undefined;

export const valuesMatch = (matchValue: string, customMessage?: string) => (value: string): undefined | string =>
  value && (matchValue !== value)
    ? customMessage || 'Values must match'
    : undefined;

export const valuesDiffer = (matchValue: string, customMessage?: string) => (value: string): undefined | string =>
  value && (matchValue !== value)
    ? undefined
    : customMessage || 'Values must differ';

export const email = (customMessage?: string) => (value: string): undefined | string =>
  value && !EMAIL.test(value)
    ? customMessage || 'This email address is invalid'
    : undefined;

export const username = (customMessage?: string) => (value: string): undefined | string =>
  !value.match(/^[a-z0-9_-]+$/gi)
    ? customMessage || 'Given username is not a valid format. Try only using letters, numbers, hyphens -, underscores (_) and no spaces in between'
    : undefined;

// Applies schema validation to the object 'values'
// Returns object with an error string for each value
export const applyValidation = (schema, values) => {
  return Object.keys(values).reduce((result, key) => {
    const value = values[key];
    const validators = schema[key];

    const errorMessage = validators.reduce((error, validator) => !error ? validator(value) : error, null);
    if (!errorMessage) return result;
    return {
      ...result,
      [key]: errorMessage,
    };
  }, {});
};

