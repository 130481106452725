// @ts-strict-ignore
import { useState } from 'react';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';
import styled from 'styled-components';
import Button from 'src/components/buttons/button';
import { text } from 'styles-js/mixins/typography';
type Props = {
  text: string;
  onClickOrHover?: () => void;
};
export default function Tooltip({
  text,
  onClickOrHover
}: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = e => {
    e.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    if (onClickOrHover && !tooltipOpen) {
      onClickOrHover();
    }
  };
  return <>
      <TooltipAnchorWrapper id={'tooltipAnchor'} data-sentry-element="TooltipAnchorWrapper" data-sentry-source-file="tooltip.tsx">
        <Info onClick={toggle} data-sentry-element="Info" data-sentry-source-file="tooltip.tsx">i</Info>
      </TooltipAnchorWrapper>
      <StyledReactstrapTooltip autohide={false} isOpen={tooltipOpen} placement="bottom" target={'tooltipAnchor'} toggle={toggle} trigger={'hover focus'} data-sentry-element="StyledReactstrapTooltip" data-sentry-source-file="tooltip.tsx">
        <TooltipBody onClick={toggle} data-sentry-element="TooltipBody" data-sentry-source-file="tooltip.tsx">
          <TooltipText data-sentry-element="TooltipText" data-sentry-source-file="tooltip.tsx">
            {text}
          </TooltipText>
          <Button icon="cross" type="none" onClick={() => setTooltipOpen(false)} data-sentry-element="Button" data-sentry-source-file="tooltip.tsx" />
        </TooltipBody>
      </StyledReactstrapTooltip>
    </>;
}
const Info = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: ${({
  theme
}) => theme.colorBlue};
  border: 2px solid ${({
  theme
}) => theme.colorBlue};
  cursor: pointer;
  margin: 0 5px;
  font-size: 14px;
  font-weight: bold;
`;
const TooltipText = styled.div`
  padding: 15px;
  text-align: left;
`;
const TooltipBody = styled.div`
  display: flex;
  align-items: flex-start;
  button {
    padding: 0;
  }
`;
const StyledReactstrapTooltip = styled(ReactstrapTooltip)`
  .tooltip {
    &.show { opacity: 1; }
    .tooltip-inner {
      ${text};
      max-width: 295px;
      background-color: ${({
  theme
}) => theme.colorYellowLight};
    }
  }
  .arrow {
    &::before {
      border-bottom-color: ${({
  theme
}) => theme.colorYellowLight} !important;
    }
  }
`;
const TooltipAnchorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;