// @ts-strict-ignore
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { NextIncomingMessage } from 'next/dist/server/request-meta';
import withReduxStore from 'src/store/with-redux-store';
import { appWithTranslation } from 'next-i18next.config';
import GlobalStyles from 'styles-js/globalStyles';
import cookieControl from 'src/utils/cookieControl';
import { trackPageView, trackPageForPartner } from 'src/utils/analytics';
import { anchorLinkOffset } from 'src/utils/scroll';
import helpScoutBeacon from 'src/utils/helpScoutBeacon';
import { setSession, clearSessionId } from 'src/utils/session';
import 'styles/base.scss';
import { handleQueryParams } from 'src/modules/shared/modal/actions';
import { setReferrerCookie, setReferrerPathCookie } from 'src/utils/cookie';
import { fetchUnreadMessagesCount, fetchUnreadNotificationsCount } from 'src/modules/shared/context/actions';
import { init as initWebsockets } from 'src/modules/websockets/websockets';
import { PageContext } from 'src/constants/types';
import { theme } from 'styles-js/theme';
const isServer = typeof window === 'undefined';
if (!isServer) {
  require('scripts/addToHomeScreen');
}
function MyApp({
  Component,
  pageProps,
  store,
  router
}: AppProps & {
  store;
}) {
  const {
    dispatch,
    getState
  } = store;
  const {
    currentUser,
    currentCommunity
  } = getState().context;
  useEffect(() => {
    // initialise 3rd party plugins
    cookieControl();
    helpScoutBeacon();
    if (window.addToHomescreen) {
      window.addToHomescreen({
        skipFirstVisit: true,
        // show only to returning visitors
        startDelay: 2,
        // display the message after that many seconds from page load
        maxDisplayCount: 1 // maximum number of times the message will be shown to the user
      });
    }

    // Hack to change offset when clicking on an anchor
    anchorLinkOffset();
    dispatch(handleQueryParams(router.query));
    setReferrerCookie(document.referrer);
    setReferrerPathCookie(router.pathname);
    if (currentUser) {
      dispatch(fetchUnreadMessagesCount());
      dispatch(fetchUnreadNotificationsCount());
      initWebsockets(dispatch);
    }
    const logPageView = (url: string) => {
      trackPageView(url, currentUser);
      trackPageForPartner(currentCommunity);
    };
    // Page view event on page load
    logPageView(location.pathname + location.search + location.hash);

    // Listen for route change
    Router.events.on('routeChangeComplete', logPageView);
    return () => Router.events.off('routeChangeComplete', logPageView);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        {/* https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
        <meta content="width=device-width,initial-scale=1" name="viewport" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
        <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
          <GlobalStyles data-sentry-element="GlobalStyles" data-sentry-source-file="_app.tsx" />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
        </ThemeProvider>
      </Provider>
    </>;
}
MyApp.getInitialProps = async (appContext: AppContext & {
  ctx: PageContext & {
    req: NextIncomingMessage;
  };
}) => {
  // set the user session
  await setSession(appContext.ctx);

  // do all the page specific getInitialProps
  const appProps = await App.getInitialProps(appContext);

  // clear the sessionId from Redux store (sessionId needed for authenticated server-side api requests)
  await clearSessionId(appContext.ctx);
  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      namespacesRequired: ['common']
    }
  };
};
export default appWithTranslation(withReduxStore(MyApp));