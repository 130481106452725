// @ts-strict-ignore
import { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { updateEmail } from 'src/modules/user/settings/actions';
import { resendVerificationLink } from 'src/modules/shared/context/actions';
import Button from 'src/components/buttons/button';
import InformationCard from 'src/components/informationCard';
import Spacer, { Size } from 'src/components/spacer';
import { Body, Footer } from 'src/components/modals/modal';
import { List, P, SubTitle } from 'src/modules/shared/modal/components/signup/onboardingScreens/sharedStyles';
import { Input } from 'styles-js/form';
import { ModalTitle } from 'styles-js/modals';
export default function CheckEmail() {
  const dispatch = useDispatch();
  const unverifiedEmail = useSelector(({
    context
  }) => context.currentUser?.unverifiedEmail);
  const [email, setEmail] = useState(unverifiedEmail);
  const [isEditing, setIsEditing] = useState(false);
  const saveEmail = () => {
    setIsEditing(false);
    dispatch(updateEmail(email, '?signup=true'));
    trackFootfallEvent(Event.Clicked, {
      clickMetadata: {
        section: Section.Onboarding
      },
      clickType: 'save-and-resend-email'
    });
  };
  const resendEmail = () => {
    dispatch(resendVerificationLink('?signup=true'));
    trackFootfallEvent(Event.Clicked, {
      clickMetadata: {
        section: Section.Onboarding
      },
      clickType: 'resend-email'
    });
  };
  return <>
      <Body data-sentry-element="Body" data-sentry-source-file="checkEmail.tsx">
        <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="checkEmail.tsx">{i18n.t('Please check your email!')}</ModalTitle>
        <P data-sentry-element="P" data-sentry-source-file="checkEmail.tsx">{i18n.t('We have sent an email to')}</P>
        {isEditing ? <InputWrapper>
            <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
            <StyledButton onClick={saveEmail}>{i18n.t('Save & resend')}</StyledButton>
          </InputWrapper> : <>
            <Spacer size={Size.M} />
            <Email>
              <strong>{email}</strong>
              <StyledButton type="link" onClick={() => setIsEditing(true)}>{i18n.t('Edit')}</StyledButton>
            </Email>
            <Spacer size={Size.L} />
          </>}
        <Spacer size={Size.L} data-sentry-element="Spacer" data-sentry-source-file="checkEmail.tsx" />
        <P data-sentry-element="P" data-sentry-source-file="checkEmail.tsx">{i18n.t('Please check your inbox and click the link provided to continue.')}</P>
        <Spacer size={Size.L} data-sentry-element="Spacer" data-sentry-source-file="checkEmail.tsx" />
        <InformationCard data-sentry-element="InformationCard" data-sentry-source-file="checkEmail.tsx">
          <SubTitle data-sentry-element="SubTitle" data-sentry-source-file="checkEmail.tsx">{i18n.t(`If you don't receive the email:`)}</SubTitle>
          <List data-sentry-element="List" data-sentry-source-file="checkEmail.tsx">
            <li>{i18n.t('Check your spam folder')}</li>
            <li>{i18n.t('Review / edit your email address')}</li>
            <li>{i18n.t('Resend the email')}</li>
          </List>
        </InformationCard>
      </Body>
      <Footer data-sentry-element="Footer" data-sentry-source-file="checkEmail.tsx">
        <Button disabled={isEditing} onClick={resendEmail} data-sentry-element="Button" data-sentry-source-file="checkEmail.tsx">
          {i18n.t('Resend email')}
        </Button>
      </Footer>
    </>;
}
const InputWrapper = styled.div`
  input {
    width: 100%;
  }
`;
const Email = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  strong {
    word-break: break-all;
  }
`;
const StyledButton = styled(Button)`
  margin: 0 auto 0 0;
`;