import styled from 'styled-components';
import { textLinkSecondary, titleSmall, titleLarge } from 'styles-js/mixins/typography';

// Variables

// Height of fade for fullscreen type on mobile and for responsive type on desktop
const paddingTop30px = '30px';
// For responsive type on mobile
const paddingTop20px = '20px';

// Components

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 50px;
  flex-shrink: 0;
  background-color: ${props => props.theme.colorBlueBackground};
`;

export const ProgressText = styled.span`
  ${titleSmall};
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ModalTitle = styled.p`
  ${titleLarge};
  display: block;
  margin-bottom: 10px;
`;

export const HeaderButton = styled.button`
  position: absolute;
  left: 0;
  margin: 0;
  padding: 10px;
  border: 0;
  appearance: none;
  background-color: transparent;
  ${textLinkSecondary};

  .icon {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
`;

export const CloseButton = styled(HeaderButton)`
  left: auto;
  right: 0;
  .icon {
    margin: 0 0 0 5px;
  }
`;

export const ResponsiveBodyContainer = styled.div`
  padding: ${paddingTop30px} 40px 40px;
  overflow-y: auto;

  @media (max-width: ${props => props.theme.screenXsMin}) {
    padding: ${paddingTop20px} 20px 30px;
  }
`;
