import Button from 'src/components/buttons/button';
import { Event } from 'src/constants/footfall';
import { trackFootfallEvent } from 'src/utils/footfall';
import i18n from 'src/utils/translate';
import ChooseUsernameForm from 'src/modules/shared/modal/components/signup/onboardingScreens/chooseUsernameForm';
import { Screens } from 'src/modules/shared/modal/components/signup/onboardingModal';
import InformationCard from 'src/components/informationCard';
import Spacer from 'src/components/spacer';
import { Body, Footer } from 'src/components/modals/modal';
import { List, SubTitle } from 'src/modules/shared/modal/components/signup/onboardingScreens/sharedStyles';
import { ModalTitle } from 'styles-js/modals';
type Props = {
  username: string;
  setCurrentScreen: (screen: Screens) => void;
  setUsername: (username: string | null) => void;
};
export default function Username({
  username,
  setUsername,
  setCurrentScreen
}: Props) {
  const onClick = () => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'join-username-entered'
    });
    setCurrentScreen(Screens.AcceptTerms);
  };
  return <>
      <Body data-sentry-element="Body" data-sentry-source-file="username.tsx">
        <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="username.tsx">{i18n.t('Create a username')}</ModalTitle>
        <ChooseUsernameForm placeholder={i18n.t('e.g. GranolaLover')} setUsername={setUsername} data-sentry-element="ChooseUsernameForm" data-sentry-source-file="username.tsx" />
        <Spacer data-sentry-element="Spacer" data-sentry-source-file="username.tsx" />
        <InformationCard data-sentry-element="InformationCard" data-sentry-source-file="username.tsx">
          <SubTitle data-sentry-element="SubTitle" data-sentry-source-file="username.tsx">{i18n.t('Tips for an anonymous username:')}</SubTitle>
          <List data-sentry-element="List" data-sentry-source-file="username.tsx">
            <li>{i18n.t(`Don't use parts of your name, address or phone number`)}</li>
            <li>{i18n.t(`Don't use the same username you have elsewhere`)}</li>
            <li>{i18n.t('Consider your favourite food, animal, music, movie, color, etc')}</li>
          </List>
        </InformationCard>
      </Body>
      <Footer data-sentry-element="Footer" data-sentry-source-file="username.tsx">
        <Button dataTestId="username-continue" disabled={!username} text={i18n.t('Continue')} onClick={onClick} data-sentry-element="Button" data-sentry-source-file="username.tsx" />
      </Footer>
    </>;
}