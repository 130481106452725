// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import { NotificationPreference } from 'src/constants/types';
import { get, getPublic, getServerSideConfig, put, putPublic } from 'src/utils/api';

const handleError = (ex: Error) => {
  captureException(ex);
  return null;
};

export async function fetchEmailPreferences(
  sessionId: string | null,
  token?: string,
  userId?: number,
): Promise<NotificationPreference[] | null> {
  let response = null;
  if (token) {
    response = await getPublic(`email-notifications?unsubscribe-key=${token}`).catch(handleError);
  } else if (userId) {
    response = await get(`private/email-notifications?user-id=${userId}`, getServerSideConfig(sessionId)).catch(handleError);
  }
  if (response?.status === 200) {
    return response?.data;
  }
  return null;
}

export async function updateEmailPreferences(
  notificationPreferences: NotificationPreference[],
  token?: string,
  userId?: number,
): Promise<NotificationPreference[] | null> {
  let response = null;
  if (token) {
    response = await putPublic(`email-notifications?unsubscribe-key=${token}`, {
      notificationPreferences,
    }).catch(handleError);
  } else if (userId) {
    response = await put('private/email-notifications', {
      userId,
      notificationPreferences,
    }).catch(handleError);
  }
  if (response?.status === 200) {
    return response?.data?.updatedPreferences;
  }
  return null;
}
