import i18n from 'src/utils/translate';

const defaultCommunityGuidelines = [
  {
    number: 1,
    title: i18n.t('Be kind, respectful, and understanding of one another'),
    subtitle: i18n.t('Actively listen & have in mind that everyone deals with their own private challenges'),
  },
  {
    number: 2,
    title: i18n.t('Post from your own experience'),
    subtitle: i18n.t('"For me, this worked..." rather than "You should do this..."'),
  },
  {
    number: 3,
    title: i18n.t('This is a space for peer to peer and emotional support'),
    subtitle: i18n.t('The community does not replace the relationship or advice given by your healthcare professional'),
  },
  {
    number: 4,
    title: i18n.t('Provide your sources of information'),
    subtitle: i18n.t('Use well-known sources of information and add the URL to your reply or post'),
  },
  {
    number: 5,
    title: i18n.t('No political content'),
    subtitle: i18n.t("Let's focus on individual health experiences"),
  },
  {
    number: 6,
    title: i18n.t('This community should not be used for commercial or personal promotion'),
    subtitle: i18n.t('No selling, no fundraising, no spam'),
  },
];

export default defaultCommunityGuidelines;

export const emptyGuidelines = {
  guidelines: [
    {
      number: 1,
      title: '',
      subtitle: '',
    },
    {
      number: 2,
      title: '',
      subtitle: '',
    },
    {
      number: 3,
      title: '',
      subtitle: '',
    },
    {
      number: 4,
      title: '',
      subtitle: '',
    },
    {
      number: 5,
      title: '',
      subtitle: '',
    },
    {
      number: 6,
      title: '',
      subtitle: '',
    },
  ],
  extendedGuidelinesUrl: '',
};
