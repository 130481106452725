const actionTypes = {
  FETCH_NEEDS_ASSESSMENT_RESULTS: 'FETCH_NEEDS_ASSESSMENT_RESULTS',
  FETCH_NEEDS_ASSESSMENT_RESULTS_SUCCESS: 'FETCH_NEEDS_ASSESSMENT_RESULTS_SUCCESS',
  FETCH_NEEDS_ASSESSMENT_RESULTS_FAILURE: 'FETCH_NEEDS_ASSESSMENT_RESULTS_FAILURE',

  FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS: 'FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS',
  FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS_SUCCESS: 'FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS_SUCCESS',
  FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS_FAILURE: 'FETCH_NEEDS_ASSESSMENT_RECOMMENDATIONS_FAILURE',
};

export default actionTypes;
