import _ from 'lodash';
import { useRouter } from 'next/router';
const defaultValues = {
  community: 'all',
  sort: 'most-relevant',
  page: 1
};
const filterKeys = ['community',
// global posts search and tag posts search
'filter',
// community members page
'page', 'query', 'sort' // global posts search, community posts search & tag posts search
];
export function useUpdateSearchParam(param: 'community' | 'sort' | 'page') {
  const router = useRouter();
  const getLinkProps = (value: string) => {
    // copy the query object, and delete all params that are not filters, we don't want to show them in the url
    const queryAs = _.pick({
      ...router.query
    }, filterKeys);
    const queryHref = {
      ...router.query
    };

    // delete page param if we are changing another param
    if (param !== 'page') {
      delete queryAs.page;
      delete queryHref.page;
    }

    // delete param if it's the default value, otherwise update it
    if (value === defaultValues[param]) {
      delete queryAs[param];
      delete queryHref[param];
    } else {
      queryAs[param] = value;
      queryHref[param] = value;
    }

    // return as and href objects
    return {
      as: {
        pathname: router.asPath.split('?')[0],
        query: queryAs
      },
      href: {
        query: queryHref
      }
    };
  };
  return getLinkProps;
}
export function useClearFilters() {
  const router = useRouter();
  const clearFilters = () => {
    // copy the query object, and delete all params that are not filters, we don't want to show them in the url
    const queryAs = _.pick({
      ...router.query
    }, filterKeys);
    const queryHref = {
      ...router.query
    };

    // delete the filters
    delete queryAs.page;
    delete queryHref.page;
    delete queryAs.community;
    delete queryHref.community;
    delete queryAs.sort;
    delete queryHref.sort;

    // push the path and as with the new params
    router.push({
      query: queryHref
    }, {
      pathname: router.asPath.split('?')[0],
      query: queryAs
    });
  };
  return clearFilters;
}