import { Action, ActionType, State } from 'src/components/alert/types';

export const initialState: State = {
  alert: null,
};

export default function Reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.ALERT_DISMISS:
      return {
        ...state,
        alert: null,
      };
    case ActionType.ALERT_SET:
      return {
        ...state,
        alert: action.alert,
      };
    default:
      return state;
  }
}
