// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/shared/context/actionTypes';
import { setAlert } from 'src/components/alert/actions';
import { showOnboardingSocial, showExistingLoginSocialModal, closeModal, SocialAuthData }  from 'src/modules/shared/modal/actions';
import { trackEvent } from 'src/utils/analytics';
import i18n from 'src/utils/translate';
import { Category, isCategoryAccepted } from 'src/utils/cookie';
import { initFacebookSignup } from 'src/utils/facebook';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import { followCommunitySignup } from 'src/modules/shared/modal/components/signup/actions';
import { Blog } from 'src/constants/types';
import { SignupFlow } from 'src/modules/shared/modal/components/signup/onboardingModal';
import { del, get, getPublic, post, postPublic } from 'src/utils/api';
import { ReduxState } from 'src/store/store';

const STATUS_CODES = {
  NO_ACCOUNT: '40400010',
  EXISTING_ACCOUNT: '40400012',
  INVALID: '40400013',
  WRONG_DETAILS: '40011221',
};

export const setServerSession = (huSessID?: string) => {
  let sessionId = huSessID;
  return async (dispatch) => {
    try {
      if (!sessionId) {
        const { data: { meta, status } } = await get('session/info');
        if (status && !status.success) {
          throw new Error(status.message);
        }
        if (!meta || !meta.session) {
          throw new Error('session/info: meta or meta.session undefined');
        }
        sessionId = meta.session;
      }

      dispatch({
        type: actionTypes.SET_SERVER_SESSION,
        sessionId,
      });
    } catch (ex) {
      captureException(ex);
    }
  };
};

export const unsetServerSession = () => {
  return {
    type: actionTypes.UNSET_SERVER_SESSION,
  };
};

const LoginError: Record<string, string> = {
  '40011221': i18n.t(`Email/password combination isn't right or email already in use.`),
  '40011222': i18n.t(`Your account has been locked. Please reset your password.`),
  '40400010': i18n.t(`Account doesn't exist, create a new one.`),
  '40400012': i18n.t(`Email already in use, login to link accounts.`),
  undefined: i18n.t('There was a problem. Please refresh the page and try again.'),
};

type Login = {
  password: string;
  username: string;
  onLoginSuccess?: () => void;
}
export const login = ({ password, username, onLoginSuccess }: Login) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LOGIN });
    try {
      const { data } = await post('session', { password, username });
      if (!data?.status?.success) throw Error();
      dispatch(closeModal());
      if (onLoginSuccess) onLoginSuccess();
      dispatch({ type: actionTypes.LOGIN_SUCCESS });
    } catch (ex) {
      dispatch(setAlert(LoginError[ex?.response?.data?.status?.responseCode], 'danger', 'none'));
      captureException(ex);
      dispatch({ type: actionTypes.LOGIN_FAILURE });
    }
  };
};

type LoginWithSocial = {
  authData: SocialAuthData;
  socialType: 'Google' | 'Facebook';
  onLoginSuccess?: () => void;
  isCommunitySignup?: boolean;
  redirectPage?: () => void;
};
export function loginWithSocial({ authData, socialType, onLoginSuccess, isCommunitySignup, redirectPage }: LoginWithSocial) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SOCIAL_LOGIN });
    try {
      const { data: { status } } = await post('session/oauth', authData);
      if (!status?.success) throw Error();
      dispatch(closeModal());
      trackEvent('Account', `Logged in via ${socialType}`);
      if (onLoginSuccess) onLoginSuccess();
      dispatch({ type: actionTypes.SOCIAL_LOGIN_SUCCESS });
    } catch (ex) {
      switch (ex?.response?.data?.status?.responseCode) {
        case STATUS_CODES.NO_ACCOUNT:
          return dispatch(showOnboardingSocial({ authData, onSignupSuccess: onLoginSuccess, isCommunitySignup, redirectPage }));
        case STATUS_CODES.EXISTING_ACCOUNT:
          // if your email is already associated with an HU account, this modal appears and attempts to link the social accounts
          return dispatch(showExistingLoginSocialModal(authData, onLoginSuccess));
        case STATUS_CODES.INVALID:
          return dispatch(setAlert(i18n.t('Invalid Login!')));
        case STATUS_CODES.WRONG_DETAILS:
          return dispatch(setAlert(LoginError[STATUS_CODES.WRONG_DETAILS], 'danger', 'none'));
        default:
          dispatch(setAlert(LoginError[ex?.response?.data?.status?.responseCode], 'danger', 'none'));
          captureException(ex);
          dispatch({ type: actionTypes.SOCIAL_LOGIN_FAILURE });
      }
    }
  };
}

type Signup = {
  password: string;
  username: string;
  email: string;
  isCommunitySignup: boolean;
}
export const signup = ({ password, username, email, isCommunitySignup }: Signup) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.SIGN_UP });

    try {
      const { context: { currentCommunity }, modal: { modalProps: { signupFlow } } } = getState();
      const isCommunityInviteFlow = signupFlow === SignupFlow.Invitation;
      const response = await postPublic('signup', { password, username, email });
      const { data: { status, payload } } = response;

      if (status && !status.success) {
        throw new Error(status.message);
      }

      if (!payload) {
        throw new Error(`User signup: payload undefined. status.message: ${status.message}`);
      } else if (!payload.user || !payload.user.id) {
        throw new Error('User signup: payload.user or payload.user.id undefined.');
      }

      await dispatch({ type: actionTypes.SIGN_UP_SUCCESS });

      if (currentCommunity && !currentCommunity.isArchived) {
        const community = { ...currentCommunity, logoUrl: getCommunityLogoUrl(currentCommunity.logoImageHash) };
        await dispatch(followCommunitySignup({ community, userId: payload.user.id }));
      }

      // if it's not a community signup (not from a community page or following a community on community search) and not a community invite signup,
      // we automatically follow them to blog
      if (!isCommunitySignup && !isCommunityInviteFlow) {
        const { data } = await getPublic('communities/blog');
        const community = { ...data as Blog, logoUrl: getCommunityLogoUrl(data.logoImageHash) };
        await dispatch(followCommunitySignup({ community, userId: payload.user.id }));
      }

    } catch (ex) {
      captureException(ex);
      dispatch(setAlert('There was a problem, please try again.'));
      dispatch({ type: actionTypes.SIGN_UP_FAILURE });
    }
  };
};

type socialSignupData = SocialAuthData & { username: string; };

export const signupWithSocial = (socialSignupData: socialSignupData, socialType: string, isCommunitySignup: boolean) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.SOCIAL_SIGN_UP });

    try {
      const { context: { currentCommunity }, modal: { modalProps: { signupFlow } } } = getState();
      const response = await postPublic('signup', socialSignupData);
      const { data: { payload, status } } = response;
      const isCommunityInviteFlow = signupFlow === SignupFlow.Invitation;

      if (status && !status.success) {
        throw new Error(status.message);
      }

      if (!payload) {
        throw new Error(`User signup: payload undefined. status.message: ${status.message}`);
      } else if (!payload.user || !payload.user.id) {
        throw new Error('User signup: payload.user or payload.user.id undefined.');
      }

      // socialType used for footfall tracking
      await dispatch({ type: actionTypes.SOCIAL_SIGN_UP_SUCCESS, socialType });

      if (currentCommunity && !currentCommunity.isArchived) {
        const community = { ...currentCommunity, logoUrl: getCommunityLogoUrl(currentCommunity.logoImageHash) };
        await dispatch(followCommunitySignup({ community, userId: payload.user.id }));
      }

      // if it's not a community signup (not from a community page or following a community on community search) and not a community invite signup,
      // we automatically follow them to blog
      if (!isCommunitySignup && !isCommunityInviteFlow) {
        const { data } = await getPublic('communities/blog');
        const community = { ...data as Blog, logoUrl: getCommunityLogoUrl(data.logoImageHash) };
        await dispatch(followCommunitySignup({ community, userId: payload.user.id }));
      }

    } catch (ex) {
      captureException(ex);
      dispatch(setAlert('There was a problem, please try again.'));
      dispatch({ type: actionTypes.SOCIAL_SIGN_UP_FAILURE });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LOGOUT });
    try {
      const response = await del('session');
      const { data: { status } } = response;

      if (status && !status.success) {
        dispatch(setAlert(status.message, 'danger', 'none'));
        return dispatch({ type: actionTypes.LOGOUT_FAILURE });
      }

      // TECH-279 If the member logged in with the Facebook Login SDK, log them out of our site and, in some cases, Facebook
      if (isCategoryAccepted(Category.Functionality)) {
        initFacebookSignup((loginStatus) => {
          if (loginStatus.status === 'connected' && window.FB) {
            // This function call may also log the person out of Facebook
            window.FB.logout(() => { // Person is now logged out
              dispatch({ type: actionTypes.LOGOUT_SUCCESS });
            });
          } else {
            dispatch({ type: actionTypes.LOGOUT_SUCCESS });
          }
        });
      } else {
        dispatch({ type: actionTypes.LOGOUT_SUCCESS });
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.LOGOUT_FAILURE });
    }
  };
};

export const isUsernameAvailable = async (username) => {
  try {
    const { data: { payload } } = await getPublic(`signup/check-username?username=${encodeURIComponent(username)}`);
    return payload?.isAvailable;
  } catch (ex) {
    captureException(ex);
    return false;
  }
};

export const isEmailAvailable = async (email) => {
  try {
    const { data: { payload } } = await getPublic(`signup/check-email?email=${encodeURIComponent(email)}`);
    return payload?.isAvailable;
  } catch (ex) {
    captureException(ex);
    return false;
  }
};
