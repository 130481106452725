import { ModalType } from 'src/constants/modalTypes';
import ActionType from 'src/modules/shared/modal/actionTypes';

type State = {
  modalType: ModalType | null;
  // TODO: type modals
  modalProps: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};
type Action = State & {
  type: ActionType;
}

const initialState = {
  modalType: null,
  modalProps: {},
};

export default function Reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.MODAL_UNSET:
      return initialState;
    case ActionType.MODAL_SET:
      if (state.modalType) {
        // [BB-3243] need to fix this more generally
        if (action.modalType === ModalType.CommunityTopConditions) return state;
      }
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case ActionType.MODAL_SET_PROPS:
      return {
        ...state,
        modalProps: action.modalProps,
      };
    default:
      return state;
  }
}
