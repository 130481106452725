// @ts-strict-ignore
import actionTypes from 'src/modules/community/communityHome/actionTypes';

export const initialState = {
  posts: [],
  filter: 'latest',
  widgets: [],
  isPostListLoading: false,
  isWidgetListLoading: false,
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_COMMUNITY_POSTS:
      return {
        ...state,
        isPostListLoading: true,
        filter: action.filter,
      };
    case actionTypes.FETCH_COMMUNITY_POSTS_FAILURE:
      return {
        ...state,
        isPostListLoading: false,
      };
    case actionTypes.FETCH_COMMUNITY_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.posts,
        isPostListLoading: false,
      };
    case actionTypes.FETCH_COMMUNITY_WIDGETS:
      return {
        ...state,
        isWidgetListLoading: true,
      };
    case actionTypes.FETCH_COMMUNITY_WIDGETS_SUCCESS:
      return {
        ...state,
        widgets: action.widgets,
        isWidgetListLoading: false,
      };
    case actionTypes.FETCH_COMMUNITY_WIDGETS_FAILURE:
      return {
        ...state,
        isWidgetListLoading: false,
      };
  }
  return state;
}
