export const FETCH_TAG_POSTS = 'FETCH_TAG_POSTS';
export const FETCH_TAG_POSTS_SUCCESS = 'FETCH_TAG_POSTS_SUCCESS';
export const FETCH_TAG_POSTS_FAILURE = 'FETCH_TAG_POSTS_FAILURE';

export const FETCH_TAG_COMMUNITIES = 'FETCH_TAG_COMMUNITIES';
export const FETCH_TAG_COMMUNITIES_SUCCESS = 'FETCH_TAG_COMMUNITIES_SUCCESS';
export const FETCH_TAG_COMMUNITIES_FAILURE = 'FETCH_TAG_COMMUNITIES_FAILURE';

export const FETCH_NHS_TAG_CONTENT = 'FETCH_NHS_TAG_CONTENT';
export const FETCH_NHS_TAG_CONTENT_SUCCESS = 'FETCH_NHS_TAG_CONTENT_SUCCESS';
export const FETCH_NHS_TAG_CONTENT_FAILURE = 'FETCH_NHS_TAG_CONTENT_FAILURE';
