import { createGlobalStyle } from 'styled-components';
import { text, textSmall } from 'styles-js/mixins/typography';

const GlobalStyle = createGlobalStyle`
  body {
    ${text};
  }

  small {
    ${textSmall};
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }

  img:not([src]) {
    visibility: hidden;
  }

  input,
  textarea {
    &:focus {
      outline: none;
      box-shadow: ${({ theme }) => theme.shadowFocus};
    }
    &:disabled {
      box-shadow: none;
    }
  }

  button {
    &:focus {
      outline: none;
      box-shadow: ${({ theme }) => theme.shadowFocus};
    }
    &:disabled {
      opacity: 0.65;
      box-shadow: none;
    }
  }

  #beacon-container { // helpScoutBeacon
    @media print {
      display: none;
    }
  }

  [contenteditable] { // fix for safari focus issue
    -webkit-user-select: text;
    user-select: text;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $color-white inset;
  }
`;

export default GlobalStyle;
