import styled from 'styled-components';
import { titleSmall } from 'styles-js/mixins/typography';

export const P = styled.p`
  margin-bottom: 10px;
`;


export const SubTitle = styled.p`
  ${titleSmall}
  margin-bottom: 10px;
`;

export const List = styled.ul`
  padding: 0 1em;
  li {
    margin-bottom: 5px;
  }
`;
