// @ts-strict-ignore
import actionTypes from 'src/modules/user/profile/actionTypes';

export const initialState = {
  profileError: null,
  currentTab: 'about',
  basics: {},
  conditions: [],
  interests: [],
  profileUser: {},
  posts: [],
  replies: [],
  followers: [],
  followings: [],
  isLoading: true,
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE:
      return {
        ...state,
      };
    case actionTypes.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        profileError: {
          status: action.error?.response?.status || 500,
        },
      };
    case actionTypes.FETCH_USER_POSTS:
    case actionTypes.FETCH_USER_REPLIES:
    case actionTypes.FETCH_CONNECTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_USER_POSTS_SUCCESS:
      return {
        ...state,
        posts: action?.createdBeforePostId ? state.posts.concat(action.data) : action.data,
        isLoading: false,
      };
    case actionTypes.FETCH_USER_REPLIES_SUCCESS:
      return {
        ...state,
        replies: action?.createdBeforePostId ? state.replies.concat(action.data) : action.data,
        isLoading: false,
      };
    case actionTypes.FETCH_CONNECTIONS_SUCCESS: {
      const { filter,  data, pageNumber } = action.data;
      const users = pageNumber > 1 ? state[filter].concat(data) : data;
      return {
        ...state,
        [filter]: users,
        isLoading: false,
      };
    }
    case actionTypes.FETCH_USER_POSTS_FAILURE:
    case actionTypes.FETCH_USER_REPLIES_FAILURE:
    case actionTypes.FETCH_CONNECTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.FOLLOW_USER_SUCCESS: {
      return {
        ...state,
        profileUser: {
          ...state.profileUser,
          isFollowedByCurrentUser: action.data.isFollowedByCurrentUser,
          totalFollowers: action.data.followers,
          totalFollowing: action.data.following,
        },
      };
    }
    case actionTypes.UNFOLLOW_USER_SUCCESS: {
      return {
        ...state,
        profileUser: {
          ...state.profileUser,
          isFollowedByCurrentUser: action.data.isFollowedByCurrentUser,
          totalFollowers: action.data.followers,
          totalFollowing: action.data.following,
        },
      };
    }
    case actionTypes.UNMUTE_USER_SUCCESS: {
      return {
        ...state,
        profileUser: {
          ...state.profileUser,
          isMuted: false,
        },
      };
    }
    case actionTypes.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab,
      };
  }
  return state;
}
