export enum ActionType {
  FETCH_COMMUNITY_ABOUT = 'FETCH_COMMUNITY_ABOUT',
  FETCH_COMMUNITY_ABOUT_SUCCESS = 'FETCH_COMMUNITY_ABOUT_SUCCESS',
  FETCH_COMMUNITY_ABOUT_FAILURE = 'FETCH_COMMUNITY_ABOUT_FAILURE',

  SAVE_ABOUT = 'SAVE_ABOUT',
  SAVE_ABOUT_SUCCESS = 'SAVE_ABOUT_SUCCESS',
  SAVE_ABOUT_FAILURE = 'SAVE_ABOUT_FAILURE',

  SET_IS_EDITING = 'SET_IS_EDITING',
  SET_SHORT_ENRICHED_DESCRIPTION = 'SET_SHORT_ENRICHED_DESCRIPTION',
}
