import styled, { css, keyframes } from 'styled-components';
export default function Loader({
  className
}: {
  className?: string;
}) {
  const elements = [];
  for (let i = 0; i < 12; i += 1) {
    elements.push(<div className={`hu-loader-element-${i}`} key={i} />);
  }
  return <HULoader className={className} data-sentry-element="HULoader" data-sentry-component="Loader" data-sentry-source-file="loader.tsx">
      {elements}
    </HULoader>;
}
const fade = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;
const HULoader = styled.div`
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
  transform: scale(0.20);
  margin: auto;
  ${() => Array.from({
  length: 12
}, (_, i) => css`
      .hu-loader-element-${i} {
        top: 80px;
        left: 93px;
        width: 14px;
        height: 40px;
        background: ${({
  theme
}) => theme.colorBlack};
        transform: rotate(${i * 30}deg) translate(0,-60px);
        border-radius: 10px;
        position: absolute;
        animation: ${fade} 1s linear infinite;
        animation-delay: ${-0.5 + i * 0.08333333333333331}s;
      }
    `)}
`;