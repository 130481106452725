// @ts-strict-ignore
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Lang } from 'src/constants/types';
import { useDispatch } from 'src/store/store';
export enum Cookie {
  Branch = 'huBv',
  CookieControl = 'CookieControl',
  Footfall = 'FOOTFALL_SESSION',
  Lang = 'huLang',
  Referrer = 'huReferrer',
  ReferrerPath = 'huReferrerPath',
  Session = 'huSessID',
}
export enum Category {
  Advertising = 'advertising',
  Analytics = 'analytics',
  Functionality = 'functionality',
}
const categoryIndexes = {
  'functionality': 0,
  'advertising': 1,
  'analytics': 2
};
const ONE_YEAR = 365;
export const setReferrerCookie = (referrer: string): string => {
  if (isCategoryAccepted(Category.Analytics) && referrer !== Cookies.get(Cookie.Referrer)) {
    return Cookies.set(Cookie.Referrer, referrer, {
      expires: ONE_YEAR
    });
  }
};
export const setReferrerPathCookie = (pathname: string): string => {
  if (isCategoryAccepted(Category.Analytics) && pathname !== Cookies.get(Cookie.ReferrerPath)) {
    return Cookies.set(Cookie.ReferrerPath, pathname, {
      expires: ONE_YEAR
    });
  }
};

// for use in components
// without useEffect, we get hydration mismatch errors
export const useBranch = (): string => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [branch, setBranch] = useState('A');
  const hubv = (router.query?.hubv as string);
  useEffect(() => {
    const branch = hubv ?? Cookies.get(Cookie.Branch) ?? 'A';
    setBranch(branch);
  }, [dispatch, hubv]);
  return branch;
};

// for use elsewhere, preferably not in components unless in useEffect
export const getBranch = (): string => {
  return Cookies.get(Cookie.Branch) || 'A';
};
export const setLangCookie = (lang: Lang): void => {
  Cookies.set(Cookie.Lang, lang, {
    expires: ONE_YEAR
  });
};
export const isCategoryAccepted = (category: Category): boolean => {
  const ccCookie = JSON.parse(Cookies.get(Cookie.CookieControl) || '{}');
  return ccCookie?.optionalCookies?.[category] === 'accepted' ? true : false;
};
export const useCookieCategory = (category: Category): [boolean, (isAccepted: boolean) => void] => {
  const [isAccepted, setIsAccepted] = useState(null);
  useEffect(() => {
    if (isAccepted === null) {
      setIsAccepted(isCategoryAccepted(category));
    } else {
      window?.CookieControl?.changeCategory(categoryIndexes[category], isAccepted);
    }
  }, [isAccepted, category]);
  return [isAccepted, setIsAccepted];
};