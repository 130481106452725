// @ts-strict-ignore
import {
  FETCH_SEARCH_POSTS_RESULTS,
  FETCH_SEARCH_POSTS_RESULTS_SUCCESS,
  FETCH_SEARCH_POSTS_RESULTS_FAILURE,
  FETCH_SEARCH_COMMUNITIES_RESULTS,
  FETCH_SEARCH_COMMUNITIES_RESULTS_SUCCESS,
  FETCH_SEARCH_COMMUNITIES_RESULTS_FAILURE,
  FETCH_SEARCH_MEMBERS_RESULTS,
  FETCH_SEARCH_MEMBERS_RESULTS_SUCCESS,
  FETCH_SEARCH_MEMBERS_RESULTS_FAILURE,
  RESET_SEARCH,
} from 'src/modules/search/shared/actionTypes';
import { Search } from 'src/constants/types';

export const initialState: Search = {
  communityFilter: 'all',
  communityFilterOptions: [],
  communityResults: [],
  currentPage: 1,
  isLoading: true,
  membersResults: [],
  searchId: null,
  searchQuery: '',
  searchResults: [],
  totalResults: 0,
};

const reducer = (state = initialState, action): Search => {
  switch (action.type) {
    case FETCH_SEARCH_POSTS_RESULTS:
      return {
        ...state,
        searchQuery: action.query,
        communityFilter: action.communityFilter,
        currentPage: action.currentPage,
        searchResults: [],
        // Solves the issue of setting a blank query
        isLoading: !!action.query,
        searchId: action.searchId,
      };
    case FETCH_SEARCH_POSTS_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchResults: action.results,
        communityFilterOptions: action.communityFilterOptions?.sort((a, b) => b.count - a.count),
        totalResults: action.totalResults,
      };
    case FETCH_SEARCH_POSTS_RESULTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_SEARCH_COMMUNITIES_RESULTS:
      return {
        ...state,
        currentPage: action.currentPage,
        searchQuery: action.query,
        communityResults: [],
        // Solves the issue of setting a blank query
        isLoading: !!action.query,
        searchId: action.searchId,
      };
    case FETCH_SEARCH_COMMUNITIES_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        communityResults: action.results,
        totalResults: action.totalResults,
      };
    case FETCH_SEARCH_COMMUNITIES_RESULTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_SEARCH_MEMBERS_RESULTS:
      return {
        ...state,
        searchQuery: action.query,
        communityFilter: action.communityFilter,
        currentPage: action.currentPage,
        membersResults: [],
        // Solves the issue of setting a blank query
        isLoading: !!action.query,
        searchId: action.searchId,
      };
    case FETCH_SEARCH_MEMBERS_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        membersResults: action.membersResults,
        totalResults: action.totalResults,
      };
    case FETCH_SEARCH_MEMBERS_RESULTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_SEARCH:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
