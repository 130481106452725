const actionTypes = {
  FETCH_USER_PROFILE: 'FETCH_USER_PROFILE',
  FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
  FETCH_USER_PROFILE_FAILURE: 'FETCH_USER_PROFILE_FAILURE',

  FETCH_USER_POSTS: 'FETCH_USER_POSTS',
  FETCH_USER_POSTS_SUCCESS: 'FETCH_USER_POSTS_SUCCESS',
  FETCH_USER_POSTS_FAILURE: 'FETCH_USER_POSTS_FAILURE',

  FETCH_USER_REPLIES: 'FETCH_USER_REPLIES',
  FETCH_USER_REPLIES_SUCCESS: 'FETCH_USER_REPLIES_SUCCESS',
  FETCH_USER_REPLIES_FAILURE: 'FETCH_USER_REPLIES_FAILURE',

  FETCH_CONNECTIONS: 'FETCH_CONNECTIONS',
  FETCH_CONNECTIONS_SUCCESS: 'FETCH_CONNECTIONS_SUCCESS',
  FETCH_CONNECTIONS_FAILURE: 'FETCH_CONNECTIONS_FAILURE',

  FOLLOW_USER: 'FOLLOW_USER',
  FOLLOW_USER_SUCCESS: 'FOLLOW_USER_SUCCESS',
  FOLLOW_USER_FAILURE: 'FOLLOW_USER_FAILURE',

  UNFOLLOW_USER: 'UNFOLLOW_USER',
  UNFOLLOW_USER_SUCCESS: 'UNFOLLOW_USER_SUCCESS',
  UNFOLLOW_USER_FAILURE: 'UNFOLLOW_USER_FAILURE',

  UNMUTE_USER_SUCCESS: 'UNMUTE_USER_SUCCESS',
  UNMUTE_USER_FAILURE: 'UNMUTE_USER_FAILURE',

  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
};

export default actionTypes;
