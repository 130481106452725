// @ts-strict-ignore
/**
 *  Isotransformation
 *  from string to `enriched Text`
 *  and from `enriched Text` to string
 */
import config from 'config/config';
import { EnrichedContent } from 'src/components/getElement';
import { BBCODE, SMILEYS, YOUTUBE_URL, URL_RE, MENTIONED_USERS_ID } from 'src/constants/regex';

const WHITE_LIST_URL = [
  'healthunlocked.com',
  'support.healthunlocked.com',
  'about.healthunlocked.com',
];

const WHITE_LIST_DEV = [
  'localhost:3000',
  'hu-staging.be',
];

/**
 * @return {string} redirect address of localhost
 */
function redirectAddress() {
  return '/redirect?url=';
}

/**
 * Return two arrays.
 * One containing the substr surrounding the pattern
 * One containing the matched patterned once processed
 * @param {str} string to process
 * @param {pattern} patterns to replace
 * @param {func} function processing the matched pattern to obtained the desired result
 */
function _split(str, { pattern, processing }) {
  const escaped = [];
  const replaced = [];

  pattern.lastIndex = 0;
  let lastIndex = pattern.lastIndex;
  let match = pattern.exec(str);
  while (match !== null) {
    escaped.push(str.substring(lastIndex, match.index));
    replaced.push(processing(...match));
    lastIndex = pattern.lastIndex;
    match = pattern.exec(str);
  }
  escaped.push(str.slice(lastIndex));

  return { escaped, replaced };
}

/**
 * Apply successuvely and exclusively the replacement of pattern in a string and an array
 * of <Text> elements
 * @param {str} string to process
 * @param {replacements}
 */
function replace({ str, style }, rep) {
  const [firstReplacement, ...replacements] = rep;
  if (str.length === 0) {
    return [];
  }
  if (!firstReplacement) {
    return [{ type: 'text', text: str, style }];
  }

  const { escaped, replaced } = _split(str, firstReplacement);

  return escaped
    .map(chunk => replace({ str: chunk, style }, replacements))
    .reduce((prev, next, index) => [...prev, ...next, replaced[index]], [])
    .filter(item => !!item);
}

/**
 * ensure the url to be complete
 * @param {url} string to process
 */
function ensureURL(url) {
  const complete = url.slice(0, 8) === 'https://' ||
    url.slice(0, 7) === 'http://' ||
    url.slice(0, 7) === 'ftps://' ||
    url.slice(0, 6) === 'ftp://';
  return (complete ? '' : 'http://') + url;
}

/**
 * @param {url} string to process
 */
function shortenUrlForDisplay(url) {
  const hasHTTPPrefix = (url.slice(0, 8) === 'https://' && 8) || (url.slice(0, 7) === 'http://' && 7);
  const unPrefixed = hasHTTPPrefix ? url.slice(hasHTTPPrefix) : url;
  return unPrefixed.length > 30 ? (unPrefixed.slice(0, 27) + '...') : unPrefixed;
}

/**
 * replace url with links
 * used as callback of RegExp.prototype.replace
 */
function replaceUrl(...args) {
  const currentWhiteList = config.environment === 'development' || config.environment === 'staging' ? WHITE_LIST_URL.concat(WHITE_LIST_DEV) : WHITE_LIST_URL;
  const matchYouTube = YOUTUBE_URL.exec(args[2]);
  const youTubeId = matchYouTube && matchYouTube[1];
  const isWhiteList = !youTubeId && currentWhiteList
    .reduce((prev, white) => prev || (args[2].slice(0, white.length) === white), false);
  const space = args[0][0] !== args[1][0] ? ' ' : '';

  return {
    isWhiteList,
    originalText: args[0],
    text: space + shortenUrlForDisplay(args[2]),
    type: 'link',
    url: isWhiteList ?
      ensureURL(args[1]) :
      `${redirectAddress()}${encodeURIComponent(ensureURL(args[1]))}`,
    youtubeID: youTubeId,
  };
}

/**
 * replace carriage return
 * used as callback of RegExp.prototype.replace
 */
function replaceReturn() {
  return { type: 'return' };
}

/**
 * replace mentions with links
 * used as callback of RegExp.prototype.replace
 */
function replaceMentions(userMap) {
  return function r(...args) {
    const user = userMap[args[1]];
    return user ? {
      type: 'mention',
      userId: user.userId,
      username: user.username,
    } : null;
  };
}

/**
 * replace characters with smileys
 * used as callback of RegExp.prototype.replace
 */
function replaceSmileys(...args) {
  return { type: 'smiley', originalText: args[0] };
}

/**
 * Determine if two style object are equal
 */
function shallowStyleEquality(styleA, styleB) {
  return (styleA.b === styleB.b) && (styleA.i === styleB.i) && (styleA.u === styleB.u);
}

/**
 * Get the interval of the string with different style according BBcode
 * @param {str} string to process
 *
 * @return [{ size, index, style }]
 *   the size of the bbtag,
 *   the index of end of the bbtag,
 *   the new style
 */
function getStyleIntervals(str) {
  const pattern = BBCODE;
  const state = { b: 0, i: 0, u: 0 };
  const styleIntervals = [];

  pattern.lastIndex = 0;
  let match = pattern.exec(str);
  while (match !== null) {
    switch (match[0]) {
      case '[b]':
        state.b += 1;
        break;
      case '[i]':
        state.i += 1;
        break;
      case '[u]':
        state.u += 1;
        break;
      case '[/b]':
        state.b = Math.max(state.b - 1, 0);
        break;
      case '[/i]':
        state.i = Math.max(state.i - 1, 0);
        break;
      case '[/u]':
        state.u = Math.max(state.u - 1, 0);
        break;
    }
    const currentStyle = { b: !!state.b, i: !!state.i, u: !!state.u };
    styleIntervals.push({ style: currentStyle, index: pattern.lastIndex, size: match[0].length });
    match = pattern.exec(str);
  }

  return styleIntervals;
}

/**
 * Get the interval of the string with different style according BBcode
 * @param {str} string to process
 *
 * @return [{ str, style }]
 *   str: ordinate partition of the original str
 *   style: style representation of the text
 */
function getStyledString(str, styleIntervals) {
  const styledString = [];

  [...styleIntervals, { style: {}, index: str.length, size: 0 }]
    .reduce((previousInterval, newInterval) => {
      const partialStr = str.slice(previousInterval.index, newInterval.index - newInterval.size);
      if (partialStr.length) {
        styledString.push({ str: partialStr, style: previousInterval.style });
      }
      return newInterval;
    }, { style: {}, index: 0, size: 0 });

  return styledString.reduce((begin, next) => {
    const prev = begin.pop();
    if (!prev) {
      return [next];
    }
    if (shallowStyleEquality(prev.style, next.style)) {
      return [...begin, { str: (prev.str + next.str), style: prev.style }];
    }
    return [...begin, prev, next];
  }, []);
}

/**
 * Strip off HTML tags and replace mentions and urls
 * @param {str} string to process
 * @param {mentionedUsers} array of mentioned users
 */
export function parseString(str, mentionedUsers): EnrichedContent {
  if (!str) return null;
  const styledString = getStyledString(str, getStyleIntervals(str));

  const replacements = [
    { pattern: /\r\n|\r|\n/g, processing: replaceReturn },
    { pattern: URL_RE, processing: replaceUrl },
    { pattern: SMILEYS, processing: replaceSmileys },
  ];

  if (mentionedUsers) {
    replacements.push({ pattern: MENTIONED_USERS_ID, processing: replaceMentions(mentionedUsers) });
  }

  const result = styledString
    .map(styledStr => replace(styledStr, replacements).filter(e => !!e))
    .reduce((previousValue, newValue) => [...previousValue, ...newValue], []) || [];

  return removeExtraReturns(result);
}

function removeExtraReturns(value) {
  const lastIndex = value.length - 1;
  const firstElement = value[lastIndex];
  if (firstElement && firstElement.type === 'return') {
    return removeExtraReturns(value.splice(0, lastIndex));
  }
  return value;
}
