//                       (https://www.healthunlocked.com/:community-slug/posts/private/:post-id)(/post-title)
export const POST_URL = /(https?:\/\/(?:www.)?healthunlocked.com\/[\w-]+\/posts\/(?:private\/)?\d+)(\/[\w-]+)?/i;

// detect the expressions of the form @[n] with an integer
export const MENTIONED_USERS_ID = /@\[([0-9]+)]/g;

// when trying to add new mentions, only letters, numbers, dash + underscore are accepted.
export const VALID_MENTION_TEXT =  /[A-Za-z0-9_-]/;
export const VALID_MENTION = /^@([A-Za-z0-9_-]*)$/;

// detect emoticons
// [8:;] - starts with any of these chars
// ['-]{0,} - optionally has an extra char, like hyphen eg, :-D
// (&#39;){0,1} - for smiley's with single quote, since single quote is encoded. eg, :'(
// [dDpPoOxX|/\)\(] - followed by one of these charset: d,D,p,P,o,O,x,X,|,),(
export const SMILEYS = /[8:;]['-]{0,}('){0,1}[dDpPoOxX|/)(](?=$|\s)/g;

// Found on http://daringfireball.net/2010/07/improved_regex_for_matching_urls
// removed checking for ) at the end of string - wiki urls have ) at the end.
export const URL_RE = /((?:(?:f|ht)tps?:\/\/(?:www\.)?|www\.)(.*?)(?=(?:[\].,;!?])*(?:\s|$)))/g;

// Regex used in BG in About page - http, https and domains like "example.com"
export const WEB_URL = /^(?:(?:ht|f)tp(?:s?):\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|co|es|br|pt|cn|org|cat|coop|int|pro|tel|xxx|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2})?)|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |/.,*:;=]|%[a-f\d]{2})*)?$/;

export const YOUTUBE_URL = /(?:youtube\.com\/watch\?v=|youtu\.be\/)(.*)/;

// special format tags for posts and replies. eg. [b]bold[/b] [i]italic[/i] [u]underline[/]
export const BBCODE = /\[\/?(?:b|i|u)]/g;

export const UNDESIRABLE_CHARACTERS = [{ pattern: /\xa0/gi, replacement: ' ' }];

// https://emailregex.com/
export const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_IN_TEXT = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const PHONE_NUMBER = /\(?([0-9]{2,})\)?[-. ]?([0-9]{3,})[-. ]?([0-9]{3,})/;

export const LINEBREAKS = /[\r\n]+/gm;
