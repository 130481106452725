// @ts-strict-ignore
import { DefaultTheme } from 'styled-components';
import defaultTheme from 'styles/lib/theme.module.scss';

interface BaseColours {
  colorBlack: string,
  colorBlackDark: string,
  colorBlackLight: string,
  colorGreyDark: string,
  colorGrey: string,
  colorGreyLight: string,
  colorWhite: string,
}

interface BrandColours {
  colorGreen: string,
  colorGreenDarker: string,
  colorGreenDark: string,
  colorGreenLight: string,
  colorGreenLighter: string,
  colorBlue: string,
  colorBlueDarker: string,
  colorBlueDark: string,
  colorBlueLight: string,
  colorBlueLighter: string,
  colorBlueBackground: string,
  colorYellow: string,
  colorYellowDark: string,
  colorYellowLight: string,
  colorPink: string,
  colorPinkDarker: string,
  colorPinkDark: string,
  colorPinkLight: string,
  colorPinkLighter: string,
  colorPurple: string,
  colorPurpleDarker: string,
  colorPurpleDark: string,
  colorPurpleLight: string,
  colorPurpleLighter: string,
  colorOrange: string,
  colorOrangeDarker: string,
  colorOrangeDark: string,
  colorOrangeLight: string,
  colorOrangeLigther: string,
}

interface AlertColours {
  colorSuccess: string,
  colorSuccessBackground: string,
  colorWarning: string,
  colorError: string,
  colorErrorBackground: string,
  colorDanger: string,
  colorDangerHover: string,
}

interface SocialMediaColours {
  colorFacebook: string,
  colorTwitter: string,
  colorInstagram: string,
  colorYoutube: string,
}

interface TextColours {
  colorTextBlack: string,
  colorTextLight: string,
  colorTextWhite: string,
  colorTextLinkPrimary: string,
  colorTextLinkPrimaryHover: string,
  colorTextLinkSecondary: string,
}

interface ButtonColours {
  colorButtonPrimary: string,
  colorButtonPrimaryHover: string,
  colorButtonPrimaryText: string,
  colorButtonSecondary: string,
  colorButtonSecondaryHover: string,
  colorButtonTag: string,
  colorButtonTagHover: string,
  colorButtonFocus: string,
}

interface TransparentColours {
  colorWhite80: string,
  colorTransparent: string,
  colorShadow: string,
}

export interface Colours extends
  BaseColours,
  BrandColours,
  AlertColours,
  SocialMediaColours,
  TextColours,
  ButtonColours,
  TransparentColours
  {}

interface Typography {
  fontFamilyMain: string,
  fontWeightNormal: string,
  fontWeightBold: string,
  fontFamilyTitle: string,
  fontWeightNormalTitle: string,
  fontWeightBoldTitle: string,
  fontSizeXs: string,
  fontLineHeightXs: string,
  fontSizeS: string,
  fontLineHeightS: string,
  fontLineHeightSWide: string,
  fontSizeM: string,
  fontLineHeightM: string,
  fontSizeL: string,
  fontLineHeightL: string,
  fontSizeXl: string,
  fontLineHeightXl: string,
}

export interface MediaQueries {
  screenXxsMin: string,
  screenXsMin: string,
  screenSmMin: string,
  screenSmMinHeight: string,
  screenMdMin: string,
  screenLgMin: string,
  screenXlMin: string,
  siteWidth: string,
  screenXxsMax: string,
  screenXsMax: string,
  screenSmMax: string,
  screenMdMax: string,
  screenLgMax: string,
  underScreenXxsmall: string,
  underScreenXsmall: string,
  underScreenSmall: string,
  underScreenIpad: string,
  underScreenMedium: string,
  underScreenLarge: string,
  underScreenXlarge: string,
  overScreenXsmall: string,
  overScreenSmall: string,
  overScreenMedium: string,
  overScreenLarge: string,
  overScreenXlarge: string,
}

interface Variables {
  cardBorderRadius: string,
  shadowDefault: string,
  shadowFocus: string,
  shadowLight: string,
  shadowTop: string,
  greyLine: string,
  zindexLiftTop: number,
  navHeightTop: string,
  navHeightBottom: string,
}
// some of these might be number!
declare module 'styled-components' {
  export interface DefaultTheme extends
    Colours,
    Typography,
    MediaQueries,
    Variables
    {}
}

// concatenated variables have quotes in them, eg `overScreenSmall = "'min-width: 768px'"`
// replace all quotes in defaultTheme
const themeWithNoQuotes = Object.entries(defaultTheme).reduce((acc, [key, value]) => {
  acc[key] = value.replace(/"/g, '');
  return acc;
}, {});


export const theme = themeWithNoQuotes as unknown as DefaultTheme;
