const actionTypes = {
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',
};

export default actionTypes;
