export enum Key {
  communityTopConditionsViewed = '__hu_community_top_conditions_viewed__',
  featuredPostsDismissed = '__hu__fp_dismissed__',
  featuredPostUniqueImpressions = '__hu__fp_uniqueimpression__',
  guidelinesDismissed = '__hu__gl_dismissed__',
  hideFloatingBox = '__hu_show_floating_box__', // this should really be *hide*_floating_box
  hideTakeProfileSurveyModal = '__hu_hide_take_profile_survey_modal__',
  lastViewedPost = '__hu_last_post__',
  lastViewedPostSecondPostBlock = '__hu_last_post-second_page_block__',
  marketingOptinPromptDate = '__hu_marketing_optin_prompt_date__',
  privacyReminderDismissed = '__hu_privacy_reminder_dismissed__',
  secondPageBlock = '__hu__second_page_block__',
  showImagesPreference = '__hu__show_images__',
  showSavedPostModalPreference = '__hu__show_save_post_modal__',
}

export const getLocalStorageItem = (key: Key): string | null => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem(key);
    }
  } catch (ex) {
    // localStorage not available
  }
  return null;
};

export const setLocalStorageItem = (key: Key, value: string): void => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem(key, value);
    }
  } catch (ex) {
    // localStorage not available
  }
};

export const removeLocalStorageItem = (key: Key): void => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.removeItem(key);
    }
  } catch (ex) {
    // localStorage not available
  }
};
