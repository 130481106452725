// @ts-strict-ignore
import Router from 'next/router';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import { close } from 'src/modules/websockets/websockets';
import { fetchMessages, fetchThread } from 'src/modules/messages/actions';
import { ActionType } from 'src/modules/messages/types';

const router = ({ dispatch }) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case contextActionTypes.LOGOUT_SUCCESS:
      close();
      Router.push('/login');
      break;
    case ActionType.MESSAGE_NEW: {
      switch (Router.route) {
        case '/messages': {
          dispatch(fetchMessages(Number(Router.query?.page) || 1));
          break;
        }
        case '/messages/thread': {
          dispatch(fetchThread({ threadId: Number(Router.query.threadId) }));
          break;
        }
      }
      break;
    }
    default:
      break;
  }

  return result;
};

export default router;
