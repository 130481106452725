// @ts-strict-ignore
/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { round } from 'lodash';
import { get, getPublic, getServerSideConfig } from 'src/utils/api';
import { Membership } from 'src/modules/community/communityMetrics/types';
import { ethnicityList } from 'src/modules/community/communityMetrics/membership/ethnicityUtil';
import { ageChartColours } from 'src/modules/community/communityMetrics/membership/age';
import { genderChartColours, getGenderLabel } from 'src/modules/community/communityMetrics/membership/gender';
import { theme } from 'styles-js/theme';
import { ReduxState } from 'src/store/store';

export const initialState: Membership = {
  endDate: null,
  location: {
    countries: [],
    countryCodes: [],
    other: [],
    status: 'idle',
    total: null,
    values: [],
  },
  age: {
    colours: [],
    labels: [],
    status: 'idle',
    total: null,
    values: [],
  },
  ethnicity: {
    colours: [],
    labels: [],
    other: [],
    status: 'idle',
    total: null,
    values: [],
  },
  gender: {
    colours: [],
    labels: [],
    status: 'idle',
    total: null,
    values: [],
  },
};

export const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.location.status = 'loading';
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        // Similar data parsing than ethnicity (see fetchEthnicity.fulfilled)
        state.endDate = action.payload.meta?.endDate;
        state.location.status = 'succeeded';
        const chartData = action.payload.rows;
        const total = chartData.reduce((sum, [, value]) => sum + value, 0);
        state.location.total = typeof total === 'number' ? total.toLocaleString() : null;

        const newData = chartData.map(([code, value]) => {
          const percentage =  value / total * 100;
          const roundedPercentage = round(percentage, 2); // Round value to two decimal places
          return ({ code, value: roundedPercentage });
        });
        const sortedNewData = newData.sort((a, b) => b.value - a.value);

        if (sortedNewData.length > 5) {
          const mainLabels = sortedNewData.filter(object => object.value >= 2);
          const otherGroup = sortedNewData.filter(object => object.value < 2);

          // If less than 5 results have a value over 2%, show the top 5 results as separate rows, and group any others as 'other'
          if (mainLabels.length <= 5 && otherGroup.length > 0) {
            const difference = 5 - mainLabels.length;
            const newMainLabels = sortedNewData.slice(0, mainLabels.length + difference);
            const newOtherGroup = otherGroup.slice(difference);
            state.location.countryCodes = newMainLabels.map(object => object.code);
            state.location.values = newMainLabels.map(object => object.value);
            state.location.other = newOtherGroup;
          } else if (otherGroup.length > 0) {
            // if 5+ results have a value over 2%, show all results over 2% as separate rows, and group any others as 'other'
            state.location.countryCodes = mainLabels.map(object => object.code);
            state.location.values = mainLabels.map(object => object.value);
            state.location.other = otherGroup;
          } else {
            // if all results have a value over 2%, show them all as separate rows
            state.location.countryCodes = sortedNewData.map(object => object.code);
            state.location.values = sortedNewData.map(object => object.value);
            state.location.other = [];
          }
        } else {
          // if there are 5 or fewer results, show them all
          state.location.countryCodes = sortedNewData.map(object => object.code);
          state.location.values = sortedNewData.map(object => object.value);
          state.location.other = [];
        }

      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.location.countries = action.payload;
      })
      .addCase(fetchAge.pending, (state) => {
        state.age.status = 'loading';
      })
      .addCase(fetchAge.fulfilled, (state, action) => {
        state.age.status = 'succeeded';
        const chartData = action.payload.rows;
        const total = chartData.reduce((sum, [, value]) => sum + value, 0);
        state.age.total = typeof total === 'number' ? total.toLocaleString() : null;

        const newData = chartData.map(([label, value]) => {
          const percentage =  value / total * 100;
          const roundedPercentage = round(percentage, 2); // Round value to two decimal places
          // Stringify labels (e.g. '18-24' changed to '18 to 24 years')
          const stringArray = label.split(new RegExp(/[+-]/, 'g')); // '-' for '18-24', '+' for '60+'
          return ({
            backendLabel: label,
            colour: ageChartColours.find(colour => colour.group === label)?.colour || theme.colorOrangeDark,
            frontendLabel: `${stringArray[0]} ${stringArray[1].length > 0 ? `to ${stringArray[1]} years` : 'years or older'}`,
            value: roundedPercentage,
          });
        });
        const sortedNewData = newData.sort((a, b) => a.backendLabel.localeCompare(b.backendLabel));
        state.age.labels = sortedNewData.map(object => object.frontendLabel);
        state.age.values = sortedNewData.map(object => object.value);
        state.age.colours = sortedNewData.map(object => object.colour);
      })
      .addCase(fetchEthnicity.pending, (state) => {
        state.ethnicity.status = 'loading';
      })
      .addCase(fetchEthnicity.fulfilled, (state, action) => {
        // Similar data parsing than location (see fetchLocation.fulfilled)
        state.ethnicity.status = 'succeeded';
        const chartData = action.payload.rows;
        const total = chartData.reduce((sum, [, value]) => sum + value, 0);
        state.ethnicity.total = typeof total === 'number' ? total.toLocaleString() : null;

        const newData = chartData.map(([label, value]) => {
          const percentage =  value / total * 100;
          const roundedPercentage = round(percentage, 2); // Round value to two decimal places
          const newLabel = ethnicityList.find(ethnicity => ethnicity.value === label);
          return ({
            colour: newLabel?.colour || theme.colorOrangeDark,
            label: newLabel?.label,
            value: roundedPercentage,
          });
        });
        const sortedNewData = newData.sort((a, b) => b.value - a.value);

        if (sortedNewData.length > 5) {
          const mainLabels = sortedNewData.filter(object => object.value >= 2);
          const otherGroup = sortedNewData.filter(object => object.value < 2);

          // If less than 5 results have a value over 2%, show the top 5 results as separate rows, and group any others as 'other'
          if (mainLabels.length <= 5 && otherGroup.length > 0) {
            const difference = 5 - mainLabels.length;
            const newMainLabels = sortedNewData.slice(0, mainLabels.length + difference);
            const newOtherGroup = otherGroup.slice(difference);
            state.ethnicity.labels = newMainLabels.map(object => object.label);
            state.ethnicity.values = newMainLabels.map(object => object.value);
            state.ethnicity.colours = newMainLabels.map(object => object.colour);
            state.ethnicity.other = newOtherGroup;
          } else if (otherGroup.length > 0) {
            // if 5+ results have a value over 2%, show all results over 2% as separate rows, and group any others as 'other'
            state.ethnicity.labels = mainLabels.map(object => object.label);
            state.ethnicity.values = mainLabels.map(object => object.value);
            state.ethnicity.colours = mainLabels.map(object => object.colour);
            state.ethnicity.other = otherGroup;
          } else {
            // if all results have a value over 2%, show them all as separate rows
            state.ethnicity.labels = sortedNewData.map(object => object.label);
            state.ethnicity.values = sortedNewData.map(object => object.value);
            state.ethnicity.colours = sortedNewData.map(object => object.colour);
            state.ethnicity.other = [];
          }
        } else {
          // if there are 5 or fewer results, show them all
          state.ethnicity.labels = sortedNewData.map(object => object.label);
          state.ethnicity.values = sortedNewData.map(object => object.value);
          state.ethnicity.colours = sortedNewData.map(object => object.colour);
          state.ethnicity.other = [];
        }

      })
      .addCase(fetchGender.pending, (state) => {
        state.gender.status = 'loading';
      })
      .addCase(fetchGender.fulfilled, (state, action) => {
        state.gender.status = 'succeeded';
        const dataTuples = action.payload.rows;
        const total = dataTuples.reduce((sum, [, value]) => sum + value, 0);
        state.gender.total = total.toLocaleString();

        const dataObject = Object.fromEntries(dataTuples);
        const dataObjectCombined = {
          ...dataObject,
          woman: (dataObject?.woman ?? 0) + (dataObject?.female ?? 0),
          man: (dataObject?.man ?? 0) + (dataObject?.male ?? 0),
        };
        delete dataObjectCombined?.['male'];
        delete dataObjectCombined?.['female'];

        const dataSorted = Object.entries(dataObjectCombined).sort(([, aValue], [, bValue]) => bValue - aValue);

        dataSorted.forEach(([key, value]) => {
          const percentage =  value / total * 100;
          const roundedPercentage = round(percentage, 2); // Round value to two decimal places

          state.gender.colours.push(genderChartColours?.[key] || theme.colorOrangeDark);
          state.gender.labels.push(getGenderLabel(key));
          state.gender.values.push(roundedPercentage);
        });
      });
  },
});

export default membershipSlice.reducer;

export const fetchLocation = createAsyncThunk<{ meta: { endDate: string }, rows: [string, number][] }, string, { state: ReduxState }>('membership/fetchLocation', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/DemographicLocation`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchCountries = createAsyncThunk<{ code: string, name: string }[], never, { state: ReduxState }>('membership/fetchCountries', async (_, thunkApi) => {
  try {
    const { data } = await getPublic('locations/countries');
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchAge = createAsyncThunk<{ rows: [string, number][] }, string, { state: ReduxState }>('membership/fetchAge', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/DemographicAge`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchEthnicity = createAsyncThunk<{ rows: [string, number][] }, string, { state: ReduxState }>('membership/fetchEthnicity', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/DemographicEthnicity`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchGender = createAsyncThunk<{ rows: [string, number][] }, string, { state: ReduxState }>('membership/fetchGender', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/DemographicGender`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
