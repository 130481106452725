import Link from 'next/link';
import styled from 'styled-components';
import { useUpdateSearchParam } from 'src/modules/search/hooks';
type Props = {
  isShowBotLinks: boolean;
  lastPageOverride: number;
  limit: number;
  maxNbPages: number;
  multiplier: number;
  page: number;
  pageSize: number;
  total: number;
};
export default function MultiplierLinks({
  isShowBotLinks,
  lastPageOverride,
  limit,
  maxNbPages,
  multiplier,
  page,
  pageSize,
  total
}: Props) {
  const getLinkParams = useUpdateSearchParam('page');
  if (!isShowBotLinks) return null;
  const lastPage = Math.ceil(total / pageSize); // do not let link get higher than this
  const maxPageNum = lastPage < lastPageOverride ? lastPage : lastPageOverride;
  const linksAboveZero = Math.floor(page / multiplier);
  const linksOffset = Math.min(linksAboveZero, Math.floor(maxNbPages / 2)); // this is links to go backward
  const links = [];
  for (let i = 1; i <= maxNbPages; i += 1) {
    const p = (linksAboveZero + i - linksOffset) * multiplier;
    links.push(p);
  }
  const filteredLinks = links.filter(p => p <= maxPageNum);
  if (filteredLinks.length <= limit) return null;
  return <Ul data-sentry-element="Ul" data-sentry-component="MultiplierLinks" data-sentry-source-file="mutiplierLinks.tsx">
      {filteredLinks.map(p => {
      const {
        as,
        href
      } = getLinkParams(String(p));
      return <li key={multiplier * p}>
            <Link as={as} href={href} scroll={false}>{p}</Link>
          </li>;
    })}
    </Ul>;
}
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  li {
    display: inline-block; 
    a {
      color: ${({
  theme
}) => theme.colorGreyDark};
      font-size: 13px;
      padding: 4px 8px;
    }
  }
`;