const environment = require('./environment');

module.exports = Object.assign({
  cdn: {
    img: environment.IMG_PATH,
    static: `${environment.IMG_PATH}/static/`,
    postImg: `${environment.IMG_PATH}/post/post_`,
    replyImg: `${environment.IMG_PATH}/response/`,
    communityLogos: `${environment.IMG_PATH}/group-logos/`,
    communityBanners: `${environment.IMG_PATH}/group-banners/`,
    communityPromoImages: `${environment.IMG_PATH}/group-campaigns/`,
    avatars: `${environment.IMG_PATH}/avatars/`,
    userBanners: `${environment.IMG_PATH}/user-banners/`,
    emoji: `${environment.IMG_PATH}/betelgeuse/img/emoji/unicode/`,
    navigator: `${environment.IMG_PATH}/programs/`,
    programs: `${environment.CDN_PATH}/solaris/programs/`,
    tags: `${environment.CDN_PATH}/solaris/tags/`,
    smiley: 'https://assets.hu-production.be/aurora/images/smiley/',
    bg: 'https://assets.hu-production.be/betelgeuse/img/',
    programsIndex: 'https://assets.hu-production.be/betelgeuse/img/index-pages/programs/',
    localesPath: `${environment.CDN_PATH}/static/locales/`,
    logos: `${environment.IMG_PATH}/static/img/logos/`,
  },

  defaultProfileImage: `${environment.IMG_PATH}/avatars/avatar_hidden_medium_200x200.jpg`,
  defaultCommunityImage: 'https://images.hu-production.be/group-logos/community_logo_default_medium_200x200.jpg',

  avatars: {
    smallSuffix: '_small@2x_100x100.jpg',
    mediumSuffix: '_medium@2x_400x400.jpg',
  },

  groups: {
    bannerSuffix: '_1170x250@2x.jpg',
    bannerSuffixSmall: '_1170x250.jpg',
    logoSuffix: '_140x140@2x.jpg',
    logoSuffixSmall: '_50x50@2x.jpg',
  },

  fb: {
    page: 'https://www.facebook.com/healthunlocked',
    appId: '542626299164413',
    scope: 'public_profile,email',
  },

  google: {
    clientId: '597276905358-r3l7ftfs10mu2jnttvme4pl3p2moaqq5.apps.googleusercontent.com',
  },

}, environment);
