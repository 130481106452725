import styled from 'styled-components';
import i18n from 'src/utils/translate';
export default function EmptyState({
  small
}: {
  small?: boolean;
}) {
  return <Container $isSmall={small} data-testid="empty-state" data-sentry-element="Container" data-sentry-component="EmptyState" data-sentry-source-file="emptyState.tsx">
      <p>{i18n.t('Your community does not have any data yet.')}</p>
      <p>{i18n.t('Please check back next month')}</p>
    </Container>;
}
export const Container = styled.div<{
  $isSmall?: boolean;
}>`
  color: ${({
  theme
}) => theme.colorGreyDark};
  text-align: center;
  margin: ${({
  $isSmall
}) => $isSmall ? '30px 0' : ' 140px 0'};

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    margin: 60px 0;
  }
`;