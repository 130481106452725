// @ts-strict-ignore
import { Category, getBranch, isCategoryAccepted } from 'src/utils/cookie';
import { isUserBot } from 'src/utils/seo';

export const IS_GOOGLE_ADS_DISABLED = true;

const SLOT_1 = '/12650263/hucustom1';
const SLOT_2 = '/12650263/hucustom2';
export const AD_ID_1 = 'gpt-ad-1';
export const AD_ID_2 = 'gpt-ad-2';

const getReferrerDomain = () => {
  const domain = window.document.referrer.split('/')[2];
  if (!domain) {
    return '';
  }
  return domain.replace(/^www\./, '');
};

const getReferrerUrlPath = () => {
  const referrerValues = window.document.referrer.split('/');
  if (referrerValues.length <= 3) return '';
  return referrerValues.splice(3).join('/');
};

export const initialiseGPT = () => {
  if (isUserBot || IS_GOOGLE_ADS_DISABLED) return;
  // Called when the user accepts cookies or on page load too if the user previously accepted them
  // Add Google Publisher Tag for Google Ad Manager (replaces DFP)
  const gpTag = document.createElement('script');
  gpTag.onload = refreshAds;
  gpTag.async = true;
  gpTag.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
  document.body.appendChild(gpTag);

  function refreshAds() {
    // Enable Google Ad Manager cookies on ad requests on the page
    window.googletag.cmd.push(() => {
      // Refresh all ads on the page for changes to take affect
      window.googletag.pubads().refresh();
    });
  }
};

// Needs to trigger everytime we change route
export const setupGPT = ({ isActive, community, postId, tagArray, pageType = '' }) => {
  if (!isCategoryAccepted(Category.Advertising)) return;

  window.googletag.cmd.push(() => {
    window.googletag.defineSlot(SLOT_1, [1, 1], AD_ID_1)?.addService(window.googletag.pubads());
    window.googletag.defineSlot(SLOT_2, [1, 1], AD_ID_2)?.addService(window.googletag.pubads());
    // set page-level key-value targeting
    window.googletag.pubads()
      .setTargeting('user', isActive ? 'member' : 'visitor')
      .setTargeting('branch', getBranch())
      .setTargeting('community', community)
      .setTargeting('post-id', postId.toString())
      .setTargeting('terms', tagArray)
      .setTargeting('source-domain', getReferrerDomain())
      .setTargeting('source-page', getReferrerUrlPath())
      .setTargeting('page-type', pageType);

    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().collapseEmptyDivs(true);
    window.googletag.enableServices(); // call all ad slots on the page in one call

    window.googletag.display(AD_ID_1);
    window.googletag.display(AD_ID_2);
  });
};

export const handleRouteChange = () => {
  window.googletag.cmd.push(() => window.googletag.destroySlots());
};
