module.exports = {
  dsn: 'https://16a8291a236a41fe8f36d3fd90c09774@o60598.ingest.us.sentry.io/173759',
  ignoreErrors: [
    // Axios response status codes that throw errors
    'Request failed with status code 310', // private community
    'Request failed with status code 400', // custom not found?
    'Request failed with status code 401', // unauthorised, page not available to visitors
    'Request failed with status code 404', // not found, could be a private community
    'Request failed with status code 410', // post deleted
    'Request failed with status code 500', // server error
    'Request failed with status code 504', // server timeout
    'Request aborted', // request interrupted for some reason
    'Network Error', // problem with user's connection
    'Unsaved changes. Route change blocked.', // Stops navigation if the user has unsaved changes and they confirm the prompt
    `Can't find variable: msDiscoverChatAvailable`,
    'Non-Error promise rejection captured with value: Object Not Found Matching Id', // error when Outlook scans a link - https://github.com/getsentry/sentry-javascript/issues/3440
  ],
};
