// @ts-strict-ignore
import { ActionType } from 'src/modules/community/communityAbout/actionTypes';
import { CommunityAbout as state } from 'src/constants/types';

const initialState: state = {
  contact: null,
  description: null,
  enrichedDescription: null,
  enrichedDescriptionShort: null,
  error: false,
  isEditingDescription: false,
  isLoading: false,
  support: null,
  supportingMembers: [],
};

export default function Reducer(state = initialState, action): state {
  switch (action.type) {
    case ActionType.FETCH_COMMUNITY_ABOUT:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.FETCH_COMMUNITY_ABOUT_SUCCESS:
      return {
        ...state,
        contact: action.contact,
        description: action.description,
        enrichedDescription:  action.enrichedDescription,
        isLoading: false,
        support: action.support,
        supportingMembers: action.supportingMembers,
      };
    case ActionType.FETCH_COMMUNITY_ABOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionType.SAVE_ABOUT:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case ActionType.SAVE_ABOUT_SUCCESS:
      return {
        ...state,
        contact: action.contact,
        description: action.description,
        enrichedDescription: action.enrichedDescription,
        isEditingDescription: false,
        isLoading: false,
        support: action.support,
      };
    case ActionType.SAVE_ABOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case ActionType.SET_IS_EDITING:
      return {
        ...state,
        isEditingDescription: true,
      };
    case ActionType.SET_SHORT_ENRICHED_DESCRIPTION:
      return {
        ...state,
        enrichedDescriptionShort: action.enrichedDescriptionShort,
      };
  }
  return state;
}
