// @ts-strict-ignore
import { ArcElement, BarElement, Chart as ChartJS, CategoryScale, Legend, LinearScale, Tooltip } from 'chart.js';
import ChartTrendline from 'chartjs-plugin-trendline';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { theme } from 'styles-js/theme';

export const setupGlobalChartJsConfig = () => {
  // Register components to all charts
  ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, ChartDataLabels, ChartTrendline, Legend);

  // Default options for all charts
  ChartJS.defaults.font.size = 14;
  ChartJS.defaults.font.family = theme.fontFamilyMain;
  ChartJS.defaults.layout.padding = 20; // avoid labels being cut off
  ChartJS.defaults.set('plugins.tooltip', {
    padding: 10,
  });
  ChartJS.defaults.set('plugins.datalabels', {
    align: 'end',
    anchor: 'end',
    color: theme.colorBlack,
    font: { weight: 'bold', size: 12 },
    offset: 0, // avoid labels being cut off
  });
  ChartJS.defaults.set('plugins.legend', {
    display: false,
  });
};

type Props = {
  context: Context;
  isMobile?: boolean;
}
export function stackedBarLabelsFormatter({ context, isMobile }: Props) {
  // Show total contributions on top of stacked bar
  const dataSetArray = [];
  context.chart.data.datasets.forEach((dataset) => {
    dataSetArray.push(dataset.data[context.dataIndex]);
  });
  const sum = dataSetArray.reduce((total, dataPoint) => total + dataPoint, 0);
  if (context.datasetIndex === dataSetArray.length - 1) {
    if (isMobile) return ''; // hide labels
    return sum;
  } else {
    return '';
  }
}

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');
  if (!listContainer) {
    listContainer = document.createElement('ul');
    legendContainer.appendChild(listContainer);
  }
  return listContainer;
};

export function createDoughnutLegends({ chart, options }) {
  const ul = getOrCreateLegendList(options.containerId);
  // Remove the old legend items
  while (ul.firstChild) {
    ul.firstChild.remove();
  }

  const items = chart.options.plugins.legend.labels.generateLabels(chart);
  items.forEach(item => {
    const li = document.createElement('li');
    // Colour - to ensure it's visible in PDFs, we create SVGs instead of divs with a background-color.
    // Otherwise, users have to tick 'Print backgrounds colours' in the print menu on Safari and Firefox.
    const namespace = 'http://www.w3.org/2000/svg';
    const colorSvg = document.createElementNS(namespace, 'svg');
    colorSvg.setAttribute('class', 'color');
    colorSvg.setAttribute('xmlns', namespace);
    colorSvg.setAttribute('width', '14');
    colorSvg.setAttribute('height', '14');
    const circle = document.createElementNS(namespace, 'circle');
    circle.setAttributeNS(null, 'cx', '7');
    circle.setAttributeNS(null, 'cy', '7');
    circle.setAttributeNS(null, 'r', '7');
    circle.setAttributeNS(null, 'fill', item.fillStyle);
    colorSvg.appendChild(circle);

    // Text
    const textSpan = document.createElement('span');
    textSpan.className = 'text';
    const text = document.createTextNode(item.text);
    textSpan.appendChild(text);
    const textContainer = document.createElement('div');
    textContainer.className = 'text-container';
    textContainer.appendChild(colorSvg);
    textContainer.appendChild(textSpan);

    // Percentage
    const percentageSpan = document.createElement('span');
    percentageSpan.className = 'percentage';
    const percentage = document.createTextNode(`${chart.data.datasets[0].data[item.index]}%`);
    percentageSpan.appendChild(percentage);

    // Add elements
    li.appendChild(textContainer);
    li.appendChild(percentageSpan);
    ul.appendChild(li);
  });
}
