// @ts-strict-ignore
import { SingleTag } from 'src/constants/types';
import {
  FETCH_TAG_POSTS,
  FETCH_TAG_POSTS_SUCCESS,
  FETCH_TAG_POSTS_FAILURE,
  FETCH_TAG_COMMUNITIES,
  FETCH_TAG_COMMUNITIES_SUCCESS,
  FETCH_TAG_COMMUNITIES_FAILURE,
  FETCH_NHS_TAG_CONTENT_SUCCESS,
  FETCH_NHS_TAG_CONTENT_FAILURE,
} from 'src/modules/tag/actionTypes';

export const initialState: SingleTag = {
  posts: {
    tagName: '',
    results: [],
    totalResults: 0,
    communityFilterOptions: [],
    isLoading: true,
  },
  communities: {
    tagName: '',
    results: [],
    totalResults: 0,
    isLoading: true,
  },
  NHS: {
    attributionLogo: '',
    attributionUrl: '',
    content: '',
    isError: false,
    isLoading: true,
  },
};

const reducer = (state = initialState, action): SingleTag => {
  switch (action.type) {
    case FETCH_TAG_POSTS:
      return {
        ...state,
        posts: {
          ...state.posts,
          tagName: action.tagName,
          isLoading: true,
          results: [],
        },
      };
    case FETCH_TAG_POSTS_SUCCESS:
      return {
        ...state,
        posts: {
          ...state.posts,
          communityFilterOptions: action.communityFilterOptions?.sort((a, b) => b.count - a.count),
          isLoading: false,
          results: action.results,
          totalResults: action.totalResults,
        },
      };
    case FETCH_TAG_POSTS_FAILURE:
      return {
        ...state,
        posts: {
          ...state.posts,
          isLoading: false,
        },
      };
    case FETCH_TAG_COMMUNITIES:
      return {
        ...state,
        communities: {
          ...state.communities,
          tagName: action.tagName,
          isLoading: true,
          results: [],
        },
      };
    case FETCH_TAG_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: {
          ...state.communities,
          isLoading: false,
          results: action.results,
          totalResults: action.totalResults,
        },
      };
    case FETCH_TAG_COMMUNITIES_FAILURE:
      return {
        ...state,
        communities: {
          ...state.communities,
          isLoading: false,
        },
      };
    case FETCH_NHS_TAG_CONTENT_SUCCESS:
      return {
        ...state,
        NHS: {
          content: action.content,
          attributionLogo: action.attributionLogo,
          attributionUrl: action.attributionUrl,
          isLoading: false,
          isError: false,
        },
      };
    case FETCH_NHS_TAG_CONTENT_FAILURE:
      return {
        ...state,
        NHS: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
