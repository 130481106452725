export const FETCH_SEARCH_POSTS_RESULTS = 'FETCH_SEARCH_POSTS_RESULTS';
export const FETCH_SEARCH_POSTS_RESULTS_SUCCESS = 'FETCH_SEARCH_POSTS_RESULTS_SUCCESS';
export const FETCH_SEARCH_POSTS_RESULTS_FAILURE = 'FETCH_SEARCH_POSTS_RESULTS_FAILURE';

export const FETCH_SEARCH_COMMUNITIES_RESULTS = 'FETCH_SEARCH_COMMUNITIES_RESULTS';
export const FETCH_SEARCH_COMMUNITIES_RESULTS_SUCCESS = 'FETCH_SEARCH_COMMUNITIES_RESULTS_SUCCESS';
export const FETCH_SEARCH_COMMUNITIES_RESULTS_FAILURE = 'FETCH_SEARCH_COMMUNITIES_RESULTS_FAILURE';

export const FETCH_SEARCH_MEMBERS_RESULTS = 'FETCH_SEARCH_MEMBERS_RESULTS';
export const FETCH_SEARCH_MEMBERS_RESULTS_SUCCESS = 'FETCH_SEARCH_MEMBERS_RESULTS_SUCCESS';
export const FETCH_SEARCH_MEMBERS_RESULTS_FAILURE = 'FETCH_SEARCH_MEMBERS_RESULTS_FAILURE';

export const RESET_SEARCH = 'RESET_SEARCH';
