// @ts-strict-ignore
import { Doughnut } from 'react-chartjs-2';
import { capitalize } from 'underscore.string';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { createDoughnutLegends } from 'src/utils/chartJs';
import EmptyState from 'src/modules/community/communityMetrics/emptyState';
import DoughnutChartFallback from 'src/modules/community/communityMetrics/doughnutChartFallback';
import { SubTitle, ChartContainer, DoughnutContainer, DoughnutCanvasContainer, LegendContainer } from 'src/modules/community/communityMetrics/sharedStyles';
import { theme } from 'styles-js/theme';
import { textSmallLight } from 'styles-js/mixins/typography';
export default function Gender() {
  const {
    colours,
    labels,
    total,
    values
  } = useSelector(({
    pages
  }) => pages.communityMetrics.membership.gender);
  const data = {
    labels,
    datasets: [{
      data: values,
      backgroundColor: colours,
      borderWidth: 1
    }]
  };
  const options = {
    layout: {
      padding: 0
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      },
      htmlLegend: {
        containerId: 'gender-legend-container'
      },
      tooltip: {
        callbacks: {
          label: context => `${context.formattedValue}%`
        },
        displayColors: false
      }
    }
  };
  const doughnutLabel = {
    id: 'doughnutLabel',
    afterDatasetsDraw(chart) {
      const {
        ctx
      } = chart;
      const centerX = chart.getDatasetMeta(0).data[0]?.x;
      const centerY = chart.getDatasetMeta(0).data[0]?.y;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = theme.colorBlack;
      ctx.font = `18px Lato, "Helvetica Neue", Helvetica, Arial, sans-serif`;
      ctx.fillText(`${total}`, centerX, centerY - 12);
      ctx.font = `14px Lato, "Helvetica Neue", Helvetica, Arial, sans-serif`;
      ctx.fillStyle = theme.colorGreyDark;
      ctx.fillText('TOTAL', centerX, centerY + 12);
    }
  };
  return <ChartContainer $noBreakInsideOnPrint={true} data-sentry-element="ChartContainer" data-sentry-component="Gender" data-sentry-source-file="gender.tsx">
      <SubTitle data-testid="chart-caption-4" data-sentry-element="SubTitle" data-sentry-source-file="gender.tsx">{i18n.t('Gender')}</SubTitle>
      {values.length > 0 ? <>
          <DoughnutContainer>
            <LegendContainer id="gender-legend-container" />
            <DoughnutCanvasContainer>
              <Doughnut aria-label={i18n.t('Gender')} data={data} fallbackContent={<DoughnutChartFallback labels={labels} total={total} values={values} />} options={options} plugins={[doughnutLabel, htmlLegendPlugin]} />
            </DoughnutCanvasContainer>
          </DoughnutContainer>
          <LegacyTermMessage>{'*'}{i18n.t('Legacy term previously used to describe genders on HealthUnlocked. Members may have selected this option before our terminology was updated.')}</LegacyTermMessage>
        </> : <EmptyState />}
    </ChartContainer>;
}
const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, _, options) {
    createDoughnutLegends({
      chart,
      options
    });
  }
};
export const genderChartColours = {
  'man': theme.colorGreen,
  'non-binary': theme.colorYellow,
  'none': theme.colorOrangeDark,
  'other': theme.colorPink,
  'self-describe': theme.colorBlue,
  'woman': theme.colorPurple
};
export function getGenderLabel(key: string) {
  switch (key) {
    case 'man':
      return i18n.t('Man (or male*)');
    case 'non-binary':
      return i18n.t('Non-binary');
    case 'none':
      return i18n.t('Prefer not to say');
    case 'other':
      return i18n.t('Other*');
    case 'self-describe':
      return i18n.t('Prefer to self-describe');
    case 'woman':
      return i18n.t('Woman (or female*)');
    default:
      return capitalize(key);
  }
}
const LegacyTermMessage = styled.p`
  ${textSmallLight}
  margin-bottom: 10px;
  @media(${({
  theme
}) => theme.overScreenSmall}) {
    margin: 20px;
  }
  @media(${({
  theme
}) => theme.overScreenMedium}) {
    margin: 40px 0 0;
  }
  @media print {
    margin: 20px;
    font-size: 12px;
  }
`;