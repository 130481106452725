// @ts-strict-ignore
import { io } from 'socket.io-client';
import config from 'config/config';
import { ClientEvent, eventToAction } from 'src/modules/websockets/actionTypes';

let socket = null;

export const init = (dispatch): void => {
  if (config.environment === 'testing') return;
  socket?.close();
  socket = io('/', { path: '/ws/' });
  eventToAction.forEach(({ event, type }) => socket.on(event, (data) => dispatch({ type, data })));
};

export const close = (): void => {
  socket?.close();
  socket = null;
};

export const chatWritingEvent = ({ isWriting, ids, threadId }: { isWriting: boolean, ids: number[], threadId: number }) => {
  socket?.emit(ClientEvent.MessagesClientWriting, { threadId, isWriting, ids });
};
