globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"a7477b2b5f5dbc5f1112837d7e80cb012e11ee82"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a user loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import sentryConfig from 'config/sentry';
import config from 'config/config';

if (config.environment === 'production') {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: config.environment,
    ignoreErrors: sentryConfig.ignoreErrors,
    // Called for message and error events
    beforeSend(event) {
      // [PROD-2052] If the error isn't coming from Axios, do not send the 'endpoint' custom tag.
      // Note: The 'endpoint' custom tag is added with setTag() in src/utils/api.ts.
      if (event.exception?.values?.[0]?.type !== 'AxiosError') {
        delete event.tags;
      }
      return event;
    },
  });
}
