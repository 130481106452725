// @ts-strict-ignore
import { combineReducers } from 'redux';
import alert from 'src/components/alert/reducer';
import communityAbout from 'src/modules/community/communityAbout/reducer';
import communityHome from 'src/modules/community/communityHome/reducer';
import communityMembers from 'src/modules/community/communityMembers/slice';
import communityMetrics from 'src/modules/community/communityMetrics/slice';
import engagement from 'src/modules/community/communityMetrics/engagement/engagementSlice';
import growth from 'src/modules/community/communityMetrics/growth/growthSlice';
import integration from 'src/modules/community/communityMetrics/integration/integrationSlice';
import membership from 'src/modules/community/communityMetrics/membership/membershipSlice';
import moderation from 'src/modules/community/communityMetrics/moderation/moderationSlice';
import communityPosts from 'src/modules/community/communityPosts/reducer';
import context from 'src/modules/shared/context/reducer';
import currentUserProfile from 'src/modules/user/shared/reducer';
import featureFlag from 'src/utils/featureFlag';
import home from 'src/modules/home/visitor/reducer';
import messages from 'src/modules/messages/reducer';
import modal from 'src/modules/shared/modal/reducer';
import needsAssessmentList from 'src/modules/needsAssessment/needsAssessmentList/reducer';
import needsAssessmentReport from 'src/modules/needsAssessment/needsAssessmentReport/reducer';
import newsfeed from 'src/modules/home/newsfeed/reducer';
import poll from 'src/modules/community/poll/reducer';
import post from 'src/modules/community/singlePost/reducer';
import profile from 'src/modules/user/profile/reducer';
import profileSurvey from 'src/modules/profileSurvey/reducer';
import programs from 'src/modules/programs/reducer';
import search from 'src/modules/search/shared/reducer';
import signupOnboarding from 'src/modules/shared/modal/components/signup/reducer';
import tag from 'src/modules/tag/reducer';
import tagsList from 'src/modules/tagsList/reducer';
import write from 'src/modules/community/write/reducer';

const reduceReducers = (...reducers) => (state, action) => reducers.reduce((acc, r) => r(acc, action), state);

export const reducer = {
  alert,
  context,
  currentUserProfile,
  featureFlag,
  modals: combineReducers({
    profileSurvey,
    signupOnboarding,
  }),
  modal,
  pages: combineReducers({
    communityAbout,
    communityHome,
    communityMembers,
    communityMetrics: combineReducers({
      common: communityMetrics,
      engagement,
      growth,
      integration,
      membership,
      moderation,
    }),
    communityPosts,
    home,
    messages,
    needsAssessmentList,
    needsAssessmentReport,
    newsfeed,
    profile,
    programs,
    search,
    singlePost: reduceReducers(post, poll),
    tag,
    tagsList,
    write,
  }),
};
