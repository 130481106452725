import { ActionType as MessageAction } from 'src/modules/messages/types';

enum Action {
  UPDATE_MESSAGES_COUNT = 'UPDATE_MESSAGES_COUNT',
  UPDATE_NOTIFICATIONS_COUNT = 'UPDATE_NOTIFICATIONS_COUNT',
}
export default Action;

export enum ServerEvent {
  MessagesNew = 'messages.new',
  MessagesUnreadCount = 'messages.unread-count',
  MessagesWriting = 'messages.writing',
  NotificationUnreadCount = 'notifications.unread-count',
}

export enum ClientEvent {
  MessagesClientWriting = 'messages.client.writing',
}

export const eventToAction = [
  {
    event: ServerEvent.MessagesUnreadCount,
    type: Action.UPDATE_MESSAGES_COUNT,
  },
  {
    event: ServerEvent.NotificationUnreadCount,
    type: Action.UPDATE_NOTIFICATIONS_COUNT,
  },
  {
    event: ServerEvent.MessagesWriting,
    type: MessageAction.MESSAGE_THREAD_ACTIVITY,
  },
  {
    event: ServerEvent.MessagesNew,
    type: MessageAction.MESSAGE_NEW,
  },
];
