// @ts-strict-ignore
import context from 'src/store/middleware/context';
import router from 'src/store/middleware/router';
import tracking from 'src/store/middleware/tracking';

export const middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat([
  context,
  router,
  tracking,
]);
