import { useEffect } from 'react';
import { theme } from 'styles-js/theme';

export function anchorLinkOffset(): void {
  window.addEventListener('hashchange', () => {
    if (location?.hash?.length !== 0) {
      window.scrollTo(window.scrollX, window.scrollY - (Number(theme.navHeightTop.replace('px', '')) - 5));
    }
  });
}

export const scrollToElementId = (id: string, timeout?: number, extraHeight = 5, animation: boolean = true): void => {
  const elementToScroll = document.getElementById(id);
  if (elementToScroll) {
    setTimeout(() => {
      window.scrollTo({
        behavior: animation ? 'smooth' : 'instant',
        top: elementToScroll.offsetTop - (Number(theme.navHeightTop.replace('px', '')) + extraHeight),
      });
    }, timeout ?? 500);
  }
};

export const useScrollToElementIdIfUrlHash = (anchor: string, timeout?: number): void => {
  useEffect(() => {
    const fragment = window.location.hash?.replace('#', '');
    if (fragment === anchor) scrollToElementId(fragment, timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
