import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import Modal from 'src/components/modals/modal';
import { hideMessage, hideMessageUndo } from 'src/modules/messages/actions';
import { P } from 'styles-js/mixins/typography';
import { LinkButton } from 'styles-js/buttons';
import { ffChatActionEnd } from 'src/modules/messages/footfall';
export default function MessageArchiveModal(): JSX.Element {
  const dispatch = useDispatch();
  const {
    id,
    section
  } = useSelector(({
    modal
  }) => modal.modalProps);
  const onAccept = () => {
    ffChatActionEnd(section, 'archive');
    dispatch(hideMessage(id));
  };
  return <Modal acceptButtonText={i18n.t('Archive')} cancelButtonText={i18n.t('Cancel')} title={i18n.t('Archive this chat?')} onAccept={onAccept} data-sentry-element="Modal" data-sentry-component="MessageArchiveModal" data-sentry-source-file="messageArchiveModal.tsx">
      <P data-sentry-element="P" data-sentry-source-file="messageArchiveModal.tsx">{i18n.t('Archived messages disappear from the inbox if inactive.')}</P>
      <P data-sentry-element="P" data-sentry-source-file="messageArchiveModal.tsx">{i18n.t('If you want to permanently stop messages from this person you need to mute them.')}</P>
    </Modal>;
}
export function ArchiveMessageSuccess({
  id
}: {
  id: number;
}): JSX.Element {
  const dispatch = useDispatch();
  return <span data-sentry-component="ArchiveMessageSuccess" data-sentry-source-file="messageArchiveModal.tsx">
      {i18n.t('Message archived.')}
      {' '}
      <LinkButton onClick={() => dispatch(hideMessageUndo(id))} data-sentry-element="LinkButton" data-sentry-source-file="messageArchiveModal.tsx">{i18n.t('Undo?')}</LinkButton>
    </span>;
}