// @ts-strict-ignore
import actionTypes from 'src/modules/home/visitor/actionTypes';

export const initialState = {
  numbers: {
    currentInteractionCount: 0,
    currentPeopleCount: 0,
    initialPeopleCount: 0,
    interactionCount: 0,
    interactionsPerSecond: 0,
    peoplePerSecond: 0,
  },
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_HOME_NUMBERS_SUCCESS:
      return {
        ...state,
        numbers: action.numbers,
      };
  }
  return state;
}
