import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getServerSideConfig } from 'src/utils/api';
import { Growth } from 'src/modules/community/communityMetrics/types';
import { ReduxState } from 'src/store/store';

export const initialState: Growth = {
  endDate: null,
  activeMembers: {
    dates: [],
    status: 'idle',
    values: [],
  },
  allTimePosts: {
    status: 'idle',
    total: null,
  },
  allTimeReplies: {
    status: 'idle',
    total: null,
  },
  newMembers: {
    dates: [],
    status: 'idle',
    values: [],
  },
  postsAndReplies: {
    dates: [],
    labels: [],
    status: 'idle',
    values: [],
  },
};

export const growthSlice = createSlice({
  name: 'growth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchNewMembers.pending, (state) => {
        state.newMembers.status = 'loading';
      })
      .addCase(fetchNewMembers.fulfilled, (state, action) => {
        state.endDate = action.payload.meta?.endDate;
        state.newMembers.status = 'succeeded';
        const chartData = action.payload.rows;
        state.newMembers.dates = chartData.map(month => month[0]);
        state.newMembers.values = chartData.map(month => month[1]);
      })
      .addCase(fetchActiveMembers.pending, (state) => {
        state.activeMembers.status = 'loading';
      })
      .addCase(fetchActiveMembers.fulfilled, (state, action) => {
        state.activeMembers.status = 'succeeded';
        const chartData = action.payload.rows;
        state.activeMembers.dates = chartData.map(month => month[0]);
        state.activeMembers.values = chartData.map(month => month[1]);
      })
      .addCase(fetchPostsAndReplies.pending, (state) => {
        state.postsAndReplies.status = 'loading';
      })
      .addCase(fetchPostsAndReplies.fulfilled, (state, action) => {
        state.postsAndReplies.status = 'succeeded';
        const chartData = action.payload.rows;

        // replies data are found in odd indexes in array
        const repliesValues = chartData.filter((value, i) => i % 2 !== 0 && value).map(month => month[1]);
        // posts data are found in even indexes
        const postsData = chartData.filter((value, i) => i % 2 === 0 && value);
        const postsValues = postsData.map(month => month[1]);
        const valuesData = postsValues.length === 0 && repliesValues.length === 0
          ? []
          : [postsValues, repliesValues];
        // Same dates for replies and posts, so getting them from posts
        const dates = postsData.map(month => month[0]);

        state.postsAndReplies.dates = dates;
        state.postsAndReplies.values = valuesData;
        state.postsAndReplies.labels = chartData.map(month => month[2]).slice(0, 2);
      })
      .addCase(fetchAllTimePosts.pending, (state) => {
        state.allTimePosts.status = 'loading';
      })
      .addCase(fetchAllTimePosts.fulfilled, (state, action) => {
        state.allTimePosts.status = 'succeeded';
        const chartData = action.payload.rows;
        const total = chartData[0]?.[0] ?? null; // allow '0' to be displayed
        state.allTimePosts.total = typeof total === 'number' ? total.toLocaleString() : null;
      })
      .addCase(fetchAllTimeReplies.pending, (state) => {
        state.allTimeReplies.status = 'loading';
      })
      .addCase(fetchAllTimeReplies.fulfilled, (state, action) => {
        state.allTimeReplies.status = 'succeeded';
        const chartData = action.payload.rows;
        const total = chartData[0]?.[0] ?? null; // allow '0' to be displayed
        state.allTimeReplies.total = typeof total === 'number' ? total.toLocaleString() : null;
      });
  },
});

export default growthSlice.reducer;

export const fetchNewMembers = createAsyncThunk<{ meta: { endDate: string }, rows: [string, number][] }, string, { state: ReduxState }>('growth/fetchNewMembers', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/NewMembersMonthly`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    // A failed request or error in a thunk will never return a rejected promise.
    // As we want to log in the errors/reason/status of the failed request to Sentry,
    // we need to return a new value using the thunkAPI.rejectWithValue utility.
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchActiveMembers = createAsyncThunk<{ rows: [string, number][] }, string, { state: ReduxState }>('growth/fetchActiveMembers', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/ActiveMembersMonthly`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchPostsAndReplies = createAsyncThunk<{ rows: [string, number, string][] }, string, { state: ReduxState }>('growth/fetchPostsAndReplies', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/PostsRepliesMonthly`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchAllTimePosts = createAsyncThunk<{ rows: number[][] }, string, { state: ReduxState }>('growth/fetchAllTimePosts', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/AllTimePosts`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchAllTimeReplies = createAsyncThunk<{ rows: number[][] }, string, { state: ReduxState }>('growth/fetchAllTimeReplies', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/AllTimeReplies`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
