import { Action, ActionType, Messages } from 'src/modules/messages/types';

export const initialState: Messages = {
  threads: [],
  pageNumber: 1,
  thread: {
    group: null,
    isDirect: null,
    isLoading: false,
    messages: [],
    participants: [],
    subject: null,
    threadId: null,
  },
  isWritingList: [],
};
export default function reducer(state = initialState, action: Action): Messages {
  switch (action.type) {
    case ActionType.FETCH_MESSAGES_SUCCESS: {
      return {
        ...state,
        threads: action.threads,
        pageNumber: action.pageNumber,
      };
    }
    case ActionType.HIDE_MESSAGE_SUCCESS: {
      const filteredThreads = state.threads.filter(({ id }) => id !== action.id);
      return {
        ...state,
        threads: filteredThreads,
      };
    }
    case ActionType.SET_MESSAGE_COMPOSE_DETAILS: {
      return {
        ...state,
        thread: {
          ...initialState.thread,
          participants: action.participants,
          subject: action.subject,
        },
      };
    }
    case ActionType.FETCH_MESSAGE_SUCCESS: {
      return {
        ...state,
        thread: action.thread,
      };
    }
    case ActionType.FETCH_PREVIOUS_MESSAGES: {
      return {
        ...state,
        thread: {
          ...state.thread,
          isLoading: true,
        },
      };
    }
    case ActionType.FETCH_PREVIOUS_MESSAGES_SUCCESS: {
      return {
        ...state,
        thread: {
          ...state.thread,
          messages: [...action.messages, ...state.thread.messages],
          isLoading: false,
        },
      };
    }
    case ActionType.FETCH_PREVIOUS_MESSAGES_FAILURE: {
      return {
        ...state,
        thread: {
          ...state.thread,
          isLoading: false,
        },
      };
    }
    case ActionType.MESSAGE_THREAD_ACTIVITY: {
      const { isWriting, threadId, userId } = action.data;
      if (isWriting) {
        return {
          ...state,
          isWritingList: [...state.isWritingList, { threadId, userId }],
        };
      } else {
        return {
          ...state,
          isWritingList: state.isWritingList.filter((w) => w.threadId !== threadId && w.userId !== userId),
        };
      }
    }
    default: {
      return state;
    }
  }
}
