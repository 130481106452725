export const POLL_FETCH = 'POLL_FETCH';
export const POLL_FETCH_SUCCESS = 'POLL_FETCH_SUCCESS';
export const POLL_FETCH_FAILURE = 'POLL_FETCH_FAILURE';

export const POLL_VOTE = 'POLL_VOTE';
export const POLL_VOTE_SUCCESS = 'POLL_VOTE_SUCCESS';
export const POLL_VOTE_FAILURE = 'POLL_VOTE_FAILURE';

export const POLL_RESULTS_SHOW = 'POLL_RESULTS_SHOW';
export const POLL_RESULTS_HIDE = 'POLL_RESULTS_HIDE';

export const DELETE_POLL = 'DELETE_POLL';
export const DELETE_POLL_SUCCESS = 'POLL_SUCCESS';
export const DELETE_POLL_FAILURE = 'POLL_FAILURE';
