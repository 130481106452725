// @ts-strict-ignore
import {
  FETCH_PROGRAM_SUCCESS,
  FETCH_MODULES_SUCCESS,
  ENROLL_USER_SUCCESS,
  ENROLL_USER_FAILURE,
  UNENROLL_USER_SUCCESS,
  UNENROLL_USER_FAILURE,
  FETCH_CURRENT_MODULE_SUCCESS,
  MARK_SECTION_AS_COMPLETE_SUCCESS,
} from 'src/modules/programs/actionTypes';
import { Programs } from 'src/constants/types';

export const initialState: Programs = {
  details: {},
  modules: [],
  progress: 0,
  isEnrolled: false,
  currentModule: null,
  isInvalidModule: false,
};

export default function Reducer(state = initialState, action): Programs {
  switch (action.type) {
    case FETCH_PROGRAM_SUCCESS:
      return {
        ...state,
        details: {
          ...action.data,
          totalEnrolledCount: action.totalEnrolled,
        },
      };
    case FETCH_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.modules,
        progress: action.progress,
        isEnrolled: action.isEnrolled,
      };
    case ENROLL_USER_SUCCESS:
      return {
        ...state,
        isEnrolled: true,
      };
    case ENROLL_USER_FAILURE:
      return {
        ...state,
        isEnrolled: false,
      };
    case UNENROLL_USER_SUCCESS:
      return {
        ...state,
        isEnrolled: false,
      };
    case UNENROLL_USER_FAILURE:
      return {
        ...state,
        isEnrolled: true,
      };
    case FETCH_CURRENT_MODULE_SUCCESS:
      return {
        ...state,
        currentModule: action.currentModule,
        isInvalidModule: action.isInvalidModule,
      };
    case MARK_SECTION_AS_COMPLETE_SUCCESS:
      return {
        ...state,
        modules: action.newModules,
        progress: action.progress,
      };
  }
  return state;
}
