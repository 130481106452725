// @ts-strict-ignore
import Router from 'next/router';
import { differenceInDays } from 'date-fns/differenceInDays';
import { truncate } from 'underscore.string';
import { showModal } from 'src/modules/shared/modal/actions';
import { fetchCurrentUser, fetchPermissions, fetchUnreadMessagesCount, fetchUnreadNotificationsCount } from 'src/modules/shared/context/actions';
import { fetchEmailPreferences } from 'src/api/emailNotifications';
import { setLocalStorageItem, getLocalStorageItem, Key, removeLocalStorageItem } from 'src/utils/localStorage';
import { ModalType } from 'src/constants/modalTypes';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import { Community } from 'src/constants/types';
import { Notification } from 'src/constants/notifications';
import { ReduxState } from 'src/store/store';

const context = ({ getState, dispatch }) => (next) => async (action) => {
  const result = next(action);
  const state = getState();
  switch (action.type) {
    case contextActionTypes.SIGN_UP_SUCCESS:
    case contextActionTypes.SOCIAL_SIGN_UP_SUCCESS:
      storeLastViewedPost(state);
      await fetchUserData(dispatch, state.context.currentCommunity);
      removeLocalStorageItem(Key.secondPageBlock);
      break;
    case contextActionTypes.LOGIN_SUCCESS:
    case contextActionTypes.SOCIAL_LOGIN_SUCCESS:
      marketingOptInPrompt(dispatch, getState);
      removeLocalStorageItem(Key.secondPageBlock);
      break;
    default:
      break;
  }

  return result;
};
export default context;

const storeLastViewedPost = (state) => {
  const isSinglePostPage = Router.pathname === '/[communitySlug]/posts/[id]/[slug]' || Router.pathname === '/[communitySlug]/posts/private/[id]/[slug]';
  const { context: { currentCommunity }, pages: { singlePost: { isSecondPageBlockMsgBoxVisible, post } } } = state;
  if (!isSinglePostPage || !post) return;

  if (isSecondPageBlockMsgBoxVisible) {
    const today = new Date();
    const lastPost = {
      authorAvatarUrl: post.author.avatarMedium,
      authorBadge: post.author.roles?.[0],
      authorUsername: post.author.username,
      communitySlug: currentCommunity?.slug,
      dateCreated: post.dateCreated,
      dateKeyCreated: today.toISOString(),
      postId: post.id,
      snippet: truncate(post.body, 160),
      title: post.title,
      isPrivate: post.isPrivate,
    };
    setLocalStorageItem(Key.lastViewedPostSecondPostBlock, JSON.stringify(lastPost));
  } else {
    const lastPost = {
      authorAvatarUrl: post.author.avatarMedium,
      authorUsername: post.author.username,
      communityName: currentCommunity?.name,
      communitySlug: currentCommunity?.slug,
      dateCreated: post.dateCreated,
      postId: post.id,
      snippet: truncate(post.body, 160),
      title: post.title,
      isPrivate: post.isPrivate,
    };
    setLocalStorageItem(Key.lastViewedPost, JSON.stringify(lastPost));
  }
};

// After a successful sign up/social sign up
const fetchUserData = async (dispatch, currentCommunity: Community) => {
  await dispatch(fetchCurrentUser());
  await dispatch(fetchPermissions(currentCommunity?.communityId));
  dispatch(fetchUnreadMessagesCount());
  dispatch(fetchUnreadNotificationsCount());
};

const marketingOptInPrompt = async (dispatch, getState: () => ReduxState) => {
  const THREE_MONTHS = 91;
  const DONT_SHOW_AFTER_JOINING = 5;
  const IGNORE_ROUTES = [
    '/unsubscribe',
    '/emailNotifications',
    '/profile/edit',
    '/user/profile',
  ];
  const today = new Date();

  // Don't show on ignored routes
  if (IGNORE_ROUTES.some(route => route === Router.pathname)) {
    return window.location.reload();
  }

  // Don't show if less than 6 months since last prompt
  const lastPromptDate = getLocalStorageItem(Key.marketingOptinPromptDate);
  if (lastPromptDate && differenceInDays(today, new Date(lastPromptDate)) < THREE_MONTHS) {
    return window.location.reload();
  }

  // Don't show if user has opted in already
  await dispatch(fetchCurrentUser());
  const state = getState();
  if (!state.context.currentUser?.dateCreated) {
    return window.location.reload();
  }
  const emailPreferences = await fetchEmailPreferences(state.context.sessionId, undefined, state.context?.currentUser?.userId);
  const isMarketingEnabled = emailPreferences?.find(({ notificationId }) => notificationId === Notification.HuResearchEmails)?.enabled;
  if (isMarketingEnabled) {
    return window.location.reload();
  }

  // Don't show if user has joined in 5 days
  const daysSinceJoining = differenceInDays(today, new Date(state.context.currentUser?.dateCreated));
  if (daysSinceJoining < DONT_SHOW_AFTER_JOINING) {
    return window.location.reload();
  }

  // Set localStorage and show modal
  setLocalStorageItem(Key.marketingOptinPromptDate, today.toISOString());
  dispatch(showModal({ modalType: ModalType.MarketingOptIn }));
};
