// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import {
  FETCH_TAG_POSTS,
  FETCH_TAG_POSTS_SUCCESS,
  FETCH_TAG_POSTS_FAILURE,
  FETCH_TAG_COMMUNITIES,
  FETCH_TAG_COMMUNITIES_SUCCESS,
  FETCH_TAG_COMMUNITIES_FAILURE,
  FETCH_NHS_TAG_CONTENT,
  FETCH_NHS_TAG_CONTENT_SUCCESS,
  FETCH_NHS_TAG_CONTENT_FAILURE,
} from 'src/modules/tag/actionTypes';
import { setAlert } from 'src/components/alert/actions';
import paginationResultsPerPage from 'src/constants/paginationResultsPerPage';
import { getPublic } from 'src/utils/api';
import { ReduxState } from 'src/store/store';

type Params = Record<string, string | number>;
export const fetchTagPosts = (tagName: string, page: number, communityFilter: string, sort: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({
      type: FETCH_TAG_POSTS,
      tagName,
    });
    if (!tagName) return;

    try {
      const limit = paginationResultsPerPage.search;
      const start = limit * (page - 1);
      const lang = getState().context.lang;

      const params: Params = {
        q: tagName,
      };

      if (start !== 0) {
        params.start = start;
      }

      if (communityFilter !== 'all') {
        params.communityIds = communityFilter;
      }

      if (sort === 'newest') {
        params.sortBy = 'date';
      }

      if (lang !== 'en') {
        params.lang = lang;
      }

      const { data: { posts, communityAggregates, total } } = await getPublic('search/posts', { params });

      dispatch({
        type: FETCH_TAG_POSTS_SUCCESS,
        totalResults: total,
        results: posts,
        communityFilterOptions: communityAggregates,
      });

    } catch (ex) {
      captureException(ex);
      dispatch({ type: FETCH_TAG_POSTS_FAILURE });
      dispatch(setAlert(ex.message));
    }
  };
};

export const fetchTagCommunities = (tagName: string, currentPage: number) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({
      type: FETCH_TAG_COMMUNITIES,
      tagName,
    });
    if (!tagName) return;

    try {
      const limit = paginationResultsPerPage.searchCommunities;
      const start = limit * (currentPage - 1);
      const lang = getState().context.lang;

      const params: Params = {
        q: tagName,
      };

      if (start !== 0) {
        params.start = start;
      }

      if (lang !== 'en') {
        params.lang = lang;
      }

      const response = await getPublic('search/communities', { params });
      const { data: { communities, total } } = response;

      dispatch({
        type: FETCH_TAG_COMMUNITIES_SUCCESS,
        totalResults: total,
        results: communities,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: FETCH_TAG_COMMUNITIES_FAILURE });
      dispatch(setAlert(ex.message));
    }
  };
};

export const fetchNHSTagContent = (tagName: string, tagType: string) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_NHS_TAG_CONTENT });

    try {
      const response = await getPublic(`nhs-tag/${tagType}/${tagName}`);
      const { data: { content, attributionLogo, attributionUrl } } = response;

      if (content === null) {
        return dispatch({ type: FETCH_NHS_TAG_CONTENT_FAILURE });
      }

      dispatch({
        type: FETCH_NHS_TAG_CONTENT_SUCCESS,
        content,
        attributionLogo,
        attributionUrl,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: FETCH_NHS_TAG_CONTENT_FAILURE });
    }
  };
};
