import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { round } from 'lodash';
import { get, getServerSideConfig } from 'src/utils/api';
import { Engagement } from 'src/modules/community/communityMetrics/types';
import { ReduxState } from 'src/store/store';

export const initialState: Engagement = {
  endDate: null,
  answerRate: {
    dates: [],
    status: 'idle',
    values: [],
  },
  avgReplies: {
    dates: [],
    status: 'idle',
    values: [],
  },
  participationRate: {
    dates: [],
    status: 'idle',
    values: [],
  },
};

export const engagementSlice = createSlice({
  name: 'engagement',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchParticipationRate.pending, (state) => {
        state.participationRate.status = 'loading';
      })
      .addCase(fetchParticipationRate.fulfilled, (state, action) => {
        state.endDate = action.payload.meta?.endDate;
        state.participationRate.status = 'succeeded';
        const chartData = action.payload.rows;
        state.participationRate.dates = chartData.map(month => month[0]);
        // Round values to two decimal places
        state.participationRate.values = chartData.map(month => round(month[1], 2));
      })
      .addCase(fetchAnswerRate.pending, (state) => {
        state.answerRate.status = 'loading';
      })
      .addCase(fetchAnswerRate.fulfilled, (state, action) => {
        state.answerRate.status = 'succeeded';
        const chartData = action.payload.rows;
        state.answerRate.dates = chartData.map(month => month[0]);
        // Round values to two decimal places
        state.answerRate.values = chartData.map(month => round(month[1], 2));
      })
      .addCase(fetchAvgReplies.pending, (state) => {
        state.avgReplies.status = 'loading';
      })
      .addCase(fetchAvgReplies.fulfilled, (state, action) => {
        state.avgReplies.status = 'succeeded';
        const chartData = action.payload.rows;
        state.avgReplies.dates = chartData.map(month => month[0]);
        state.avgReplies.values = chartData.map(month => Math.round(month[1]));
      });
  },
});

export default engagementSlice.reducer;

export const fetchParticipationRate = createAsyncThunk<{ meta: { endDate: string }, rows: [string, number][] }, string, { state: ReduxState }>('engagement/fetchParticipationRate', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/MemberParticipationRateMonthly`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchAnswerRate = createAsyncThunk<{ rows: [string, number][] }, string, { state: ReduxState }>('engagement/fetchAnswerRate', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/PostAnswerRateMonthly`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchAvgReplies = createAsyncThunk<{ rows: [string, number][] }, string, { state: ReduxState }>('engagement/fetchAvgReplies', async (slug, thunkApi) => {
  try {
    const { data } = await get(`private/communities/${slug}/metrics/AvgRepliesPerPostsMonthly`, getServerSideConfig(thunkApi.getState().context.sessionId));
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
