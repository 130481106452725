import { useEffect, useState } from 'react';

// Like useState but `debouncedValue` will only update after `delay` time
export default function useDebouncedState<T>(defaultValue: T, delay?: number): [T, (t: T) => void] {
  const [value, setValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 300);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return [debouncedValue, setValue];
}
