import i18n from 'src/utils/translate';

export const emailMaxLength = 100; // db limit

export const emailErrors = {
  required: i18n.t('Email is required'),
  max: i18n.t('Emails must not exceed %1$s characters').replace('%1$s', `${emailMaxLength}`),
  invalid: i18n.t('Enter a valid email, e.g. "john@email.com"'),
  match: i18n.t('Emails must match'),
};

export const passwordMinLength = 6;
export const passwordMaxLength = 100; // db limit

export const passwordErrors = {
  required: i18n.t('Password is required'),
  min: i18n.t('Passwords must have at least %1$s characters').replace('%1$s', `${passwordMinLength}`),
  max: i18n.t('Passwords must not exceed %1$s characters').replace('%1$s', `${passwordMaxLength}`),
  match: i18n.t('Passwords must match'),
};

export const usernameMinLength = 4;
export const usernameMaxLength = 20;
export const usernameErrors = {
  required: i18n.t('Username is required'),
  min: i18n.t('Usernames must have at least %1$s characters').replace('%1$s', `${usernameMinLength}`),
  max: i18n.t('Usernames must not exceed %1$s characters').replace('%1$s', `${usernameMaxLength}`),
  notAvailable: i18n.t('Username is not available'),
};
