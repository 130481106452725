// @ts-strict-ignore
import actionTypes from 'src/modules/home/newsfeed/actionTypes';
import { Newsfeed as state } from 'src/constants/types';

const initialState: state = {
  hasMorePosts: true,
  isInfiniteScroll: false,
  isLoading: true,
  posts: [],
};

export default function reducer(state = initialState, action): state {
  switch (action.type) {
    case actionTypes.FETCH_NEWSFEED:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_NEWSFEED_SUCCESS:
      return {
        ...state,
        hasMorePosts: action.data?.length < 30 ? false : true,
        isInfiniteScroll: action.isInfiniteScroll,
        isLoading: false,
        posts: action.isInfiniteScroll
          ? [...state.posts, ...action.data]
          : action.data,
      };
    case actionTypes.FETCH_NEWSFEED_FAILURE:
      return {
        ...state,
        hasMorePosts: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
