// @ts-strict-ignore
import { useState } from 'react';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { updateEmailPreferences } from 'src/api/emailNotifications';
import { setAlert } from 'src/components/alert/actions';
import { Notification } from 'src/constants/notifications';
import { Screens, SignupFlow, useFinishOnboarding, useIsSkipFindCommunity } from 'src/modules/shared/modal/components/signup/onboardingModal';
import InformationCard from 'src/components/informationCard';
import Button from 'src/components/buttons/button';
import { Body, Footer } from 'src/components/modals/modal';
import { List, P } from 'src/modules/shared/modal/components/signup/onboardingScreens/sharedStyles';
import { LinkButton } from 'styles-js/buttons';
import { ModalTitle } from 'styles-js/modals';
type Props = {
  setCurrentScreen: (screen: Screens) => void;
};
export default function ResearchOptIn({
  setCurrentScreen
}: Props) {
  const dispatch = useDispatch();
  const userId = useSelector(({
    context
  }) => context.currentUser?.userId);
  const {
    isSocialSignup,
    signupFlow
  } = useSelector(({
    modal
  }) => modal.modalProps);
  const isSkipFindCommunity = useIsSkipFindCommunity();
  const [isDisabled, setIsDisabled] = useState(false);
  const finishOnboarding = useFinishOnboarding(setCurrentScreen);
  const onClick = async (isOptIn: boolean) => {
    setIsDisabled(true);
    trackFootfallEvent(Event.Clicked, {
      clickType: `opt-in-research-${isOptIn ? 'yes' : 'no'}`
    });
    const updatedPreferences = await updateEmailPreferences([{
      notificationId: Notification.HuResearchEmails,
      enabled: isOptIn
    }], null, userId);
    if (updatedPreferences) {
      if (!isSocialSignup && signupFlow !== SignupFlow.Invitation) {
        setCurrentScreen(Screens.CheckEmail);
      } else if (isSkipFindCommunity) {
        finishOnboarding();
      } else {
        setCurrentScreen(Screens.FindCommunities);
      }
    } else {
      setIsDisabled(false);
      dispatch(setAlert('There was a problem, please try again.'));
    }
  };
  return <>
      <Body data-sentry-element="Body" data-sentry-source-file="researchOptIn.tsx">
        <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="researchOptIn.tsx">{i18n.t('Help advance research benefiting people like you')}</ModalTitle>
        <P data-sentry-element="P" data-sentry-source-file="researchOptIn.tsx">{i18n.t('May we send you occasional emails about opportunities that might interest you?')}</P>
        <List data-sentry-element="List" data-sentry-source-file="researchOptIn.tsx">
          <li>{i18n.t('Paid market research')}</li>
          <li>{i18n.t('Surveys')}</li>
          <li>{i18n.t('Clinical trials')}</li>
          <li>{i18n.t('New communities')}</li>
        </List>
        <InformationCard data-sentry-element="InformationCard" data-sentry-source-file="researchOptIn.tsx">
          <p>
            {i18n.t('It will always be HealthUnlocked that emails you. And you can opt out at any time.')}
            {' '}
            <LinkButton onClick={() => {
            helpScoutArticle('154-opportunities-by-email', {
              type: 'modal'
            });
            trackFootfallEvent(Event.Clicked, {
              clickType: 'join-research-learn-more'
            });
          }} data-sentry-element="LinkButton" data-sentry-source-file="researchOptIn.tsx">
              {i18n.t('Learn more')}{' >'}
            </LinkButton>
          </p>
        </InformationCard>
      </Body>
      <Footer data-sentry-element="Footer" data-sentry-source-file="researchOptIn.tsx">
        <Button dataTestId="research-no" disabled={isDisabled} type="link" onClick={() => onClick(false)} data-sentry-element="Button" data-sentry-source-file="researchOptIn.tsx">
          {i18n.t('No thanks')}
        </Button>
        <Button dataTestId="research-yes" disabled={isDisabled} onClick={() => onClick(true)} data-sentry-element="Button" data-sentry-source-file="researchOptIn.tsx">
          {i18n.t('Yes')}
        </Button>
      </Footer>
    </>;
}