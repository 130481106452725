import { ExtendedMember } from '..';
import { CurrentUserProfile } from 'src/constants/types/currentUserProfile';
import { PostType } from 'src/constants/types/post';

export type Profile = CurrentUserProfile & {
  currentTab: ProfileTab,
  followers: ExtendedMember[],
  followings: ExtendedMember[],
  profileError: { status: number } | null,
  profileUser: ProfileUser,
  posts: ProfilePost[],
  replies: ProfileReply[],
}

export type ProfileUser = {
  dateCreated: string,
  isDeceased: boolean,
  isFollowedByCurrentUser?: boolean,
  isMuted?: boolean,
  isOwnProfile: boolean,
  isUserFollowsEnabled: boolean,
  isUsersImFollowingPublic: boolean,
  totalFollowing: number,
  totalFollowers: number,
  totalPosts: number,
  totalReplies: number,
  userId: number,
  username: string,
}

export enum ProfileTab {
  About = 'about',
  Posts = 'posts',
  Replies = 'replies',
  Connections = 'connections',
}

export enum Connections {
  Followers = 'followers',
  Followings = 'followings',
}

type Community = {
  communityId: number;
  isPrivate: boolean;
  name: string;
  slug: string;
}

export type ProfileReply = {
  bodySnippet: string;
  community: Community;
  dateCreated: string;
  isLocked: boolean;
  isPrivate: boolean;
  postId: number;
  postImageHash?: string;
  postTypeId: PostType.Reply;
  superParent: {
    postId: number;
    postTypeId: PostType;
    dateCreated: string;
  };
  title: string;
  totalResponses: number;
  urlEncodedTitle: string;
}

export type ProfilePost = {
  bodySnippet: string;
  community: Community;
  dateCreated: string;
  isLocked: boolean;
  isPrivate: boolean;
  postId: number;
  postImageHash?: string;
  postTypeId: PostType;
  title: string;
  totalResponses: number;
  urlEncodedTitle: string;
}
