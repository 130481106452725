// @ts-strict-ignore
import {
  FETCH_TAGS_LIST_SUCCESS,
  FETCH_TAGS_LIST_FAILURE,
} from 'src/modules/tagsList/actionTypes';
import { TagsList } from 'src/constants/types';

const initialState: TagsList = {
  letter: '',
  tagsList: [],
};

export default function Reducer(state = initialState, action): TagsList {
  switch (action.type) {
    case FETCH_TAGS_LIST_SUCCESS:
      return {
        letter: action.letter,
        tagsList: action.tagsList,
      };
    case FETCH_TAGS_LIST_FAILURE:
      return {
        ...initialState,
      };
  }
  return state;
}
