// @ts-strict-ignore
const FEATURE_FLAG_UNSET = 'FEATURE_FLAG_UNSET';
const FEATURE_FLAG_SET = 'FEATURE_FLAG_SET';

export const enableFlag = () => {
  return { type: FEATURE_FLAG_SET };
};

export const disableFlag = () => {
  return { type: FEATURE_FLAG_UNSET };
};

const initialState = {
  enabled: false,
};

export default function FeatureFlag(state = initialState, action) {
  switch (action.type) {
    case FEATURE_FLAG_SET:
      return {
        enabled: true,
      };
    case FEATURE_FLAG_UNSET:
      return initialState;
  }
  return state;
}
