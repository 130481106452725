// @ts-strict-ignore
import { configureStore, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import {
  useSelector as useReduxSelector, // eslint-disable-line @typescript-eslint/no-restricted-imports
  useDispatch as useReduxDispatch, // eslint-disable-line @typescript-eslint/no-restricted-imports
  type TypedUseSelectorHook,
} from 'react-redux';

import { middleware } from 'src/store/middlewares';
import { reducer } from 'src/store/reducers';

let store: ReduxStore;
export const initStore = (preloadedState = {}) => {
  const store = configureStore({
    middleware,
    preloadedState,
    reducer,
  });
  return store;
};

export const useDispatch = () => useReduxDispatch<ThunkDispatch<ReduxState, never, UnknownAction>>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector;

/* Types */
export type ReduxStore = ReturnType<typeof initStore>;
export type ReduxState = ReturnType<typeof store.getState>;
