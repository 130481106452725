// @ts-strict-ignore
import actionTypes from 'src/modules/community/communityPosts/actionTypes';
import { CommunityPosts } from 'src/constants/types';

export const initialState: CommunityPosts = {
  hasMorePosts: true,
  latestPoll: null,
  pinnedPosts: [],
  posts: [],
  topTags: [],
};

export default function Reducer(state = initialState, action): CommunityPosts {
  switch (action.type) {
    case actionTypes.FETCH_COMMUNITY_POSTS_PAGE_POSTS:
      return {
        ...state,
        archive: {
          currentYear: action.archiveCurrentYear,
          currentMonth: action.archiveCurrentMonth,
          sidebarModuleData: [],
        },
        filter: action.filter,
        isPostListLoading: !action.isSeeMoreClick && true,
        selectedTopic: action.selectedTopic,
        selectedTopicId: action.selectedTopicId,
      };
    case actionTypes.FETCH_COMMUNITY_POSTS_PAGE_POSTS_SUCCESS: {
      const newLastPostId = action.posts.length > 0 ? action.posts[action.posts.length - 1].postId : null;

      // On clicking the 'See more posts' button, received 30 posts from the previous API call
      // so the 'See more button' was visible but there are no more posts to display
      if (action.isSeeMoreClick && !action.hasMorePosts && action.posts.length === 0) {
        return {
          ...state,
          hasMorePosts: false,
        };
      }
      // On clicking the 'See more posts' button, merge existing and new posts
      else if (action.isSeeMoreClick) {
        return {
          ...state,
          currentPageNumber: action.currentPageNumber,
          hasMorePosts: action.hasMorePosts,
          lastPostId: newLastPostId,
          posts: [
            ...state.posts,
            ...action.posts,
          ],
        };
      }
      // On page load or filter change or topic change, replace posts
      else {
        return {
          ...state,
          currentPageNumber: action.currentPageNumber,
          hasMorePosts: action.hasMorePosts,
          isPostListLoading: false,
          lastPostId: newLastPostId,
          posts: action.posts,
        };
      }
    }
    case actionTypes.FETCH_COMMUNITY_POSTS_PAGE_POSTS_FAILURE:
      return {
        ...state,
        isPostListLoading: false,
      };
    case actionTypes.FETCH_COMMUNITY_TOP_TAGS_SUCCESS:
      return {
        ...state,
        topTags: action.topTags,
      };
    case actionTypes.FETCH_POSTS_PINNED_POSTS_SUCCESS:
      return {
        ...state,
        pinnedPosts: action.pinnedPosts,
      };
    case actionTypes.FETCH_LATEST_POLL_SUCCESS:
      return {
        ...state,
        latestPoll: action.latestPoll,
      };
    case actionTypes.FETCH_ARCHIVE_SUCCESS:
      return {
        ...state,
        archive: {
          ...state.archive,
          sidebarModuleData: action.archiveData,
        },
      };
    case actionTypes.FETCH_TOTAL_MONTHLY_POSTS_SUCCESS:
      return {
        ...state,
        totalMonthlyPosts: action.totalMonthlyPosts,
      };
  }
  return state;
}
