import { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { text, textSmall } from 'styles-js/mixins/typography';
import Tooltip from 'src/components/tooltip';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Button from 'src/components/buttons/button';
import Icon from 'src/components/icon';
import Avatar, { Size } from 'src/components/avatar';
import { followCommunitySignup, unfollowCommunitySignup } from 'src/modules/shared/modal/components/signup/actions';
type Props = {
  slug: string;
  name: string;
  followerCount: number;
  logoUrl: string;
  isFollowed?: boolean;
  isHidden?: boolean;
};
export default function FindCommunityCard({
  slug,
  name,
  followerCount = 0,
  logoUrl,
  isFollowed = false,
  isHidden = false
}: Props) {
  const [disabled, setDisabled] = useState(false);
  const currentUserId = useSelector(({
    context
  }) => context.currentUser?.userId);
  const dispatch = useDispatch();
  const onClick = () => {
    if (disabled || !currentUserId) return;
    setDisabled(true);
    if (isFollowed) {
      dispatch(unfollowCommunitySignup({
        communitySlug: slug,
        userId: currentUserId
      }));
    } else {
      dispatch(followCommunitySignup({
        community: {
          slug,
          name,
          followerCount,
          logoUrl
        },
        userId: currentUserId
      }));
    }
    setDisabled(false);
  };
  const onClickTooltip = () => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'info-icon-read-only-community',
      clickMetadata: {
        section: Section.CommunitiesJoin
      }
    });
  };
  if (isHidden) return null;
  return <StyledButton dataTestId={isFollowed ? 'community-unfollow' : 'community-follow'} type="none" onClick={onClick} data-sentry-element="StyledButton" data-sentry-component="FindCommunityCard" data-sentry-source-file="findCommunityCard.tsx">
      <Avatar alt={name + ' ' + i18n.t('logo')} size={Size.Small} url={logoUrl} data-sentry-element="Avatar" data-sentry-source-file="findCommunityCard.tsx" />
      <TextWrapper data-sentry-element="TextWrapper" data-sentry-source-file="findCommunityCard.tsx">
        <Name className="community-title" data-sentry-element="Name" data-sentry-source-file="findCommunityCard.tsx">{name}</Name>
        <Description data-sentry-element="Description" data-sentry-source-file="findCommunityCard.tsx">
          <Followers data-sentry-element="Followers" data-sentry-source-file="findCommunityCard.tsx">{followerCount.toLocaleString()}{' '}{i18n.t('members')}</Followers>
          {slug === 'blog' && <Tooltip text={i18n.t('This is a read-only community to share HealthUnlocked news.')} onClickOrHover={onClickTooltip} />}
        </Description>
      </TextWrapper>
      {isFollowed ? <StyledIcon icon="cross" /> : <StyledIcon icon="plus" />}
    </StyledButton>;
}
const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  gap: 10px;
  &:hover .icon::before {
    color: ${({
  theme
}) => theme.colorButtonPrimaryHover};
  }
`;
const TextWrapper = styled.div`
  flex: 1;
`;
const Name = styled.div`
  ${text};
  text-align: left;
  padding-right: 4px;
  white-space: pre-line;
`;
const Followers = styled.div`
  ${textSmall};
  color: ${props => props.theme.colorTextLight};
  text-align: left;
`;
const Description = styled.div`
  display: flex;
`;
const StyledIcon = styled(Icon)`
  color: ${({
  theme
}) => theme.colorTextLinkPrimary};
  &::before {
    transition: all 0.3s ease;
    font-weight: bold;
    font-size: 20px;
   }
`;