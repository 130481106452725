// @ts-strict-ignore
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { subMonths } from 'date-fns/subMonths';
import { addMonths } from 'date-fns/addMonths';
import { format } from 'date-fns/format';

type Data = [string, number, string][];
type ReasonData = { date: string, value: number };
type Reason = { reason: string, data: ReasonData[] };

export const groupDataByReason = (data: Data): Reason[] => {
  return data.reduce<Reason[]>((acc, current) => {
    const dataPoint = { date: current[0], value: current[1] };
    if (acc.length === 0 || !acc.find(object => object.reason === current[2])) {
      acc.push({ reason: current[2], data: [dataPoint] });
    } else {
      return acc.map(object => {
        if (object.reason === current[2]) return { ...object, data: [...object.data, dataPoint] };
        return object;
      });
    }
    return acc;
  }, []);
};

export const addStartAndEndDates = (filteredDates: string[], startDate: string, endDate: string): string[] => {
  const startStrings = startDate.split('-');
  const newStartDate = new Date(`${startStrings[0]}-${startStrings[1]}`).toISOString();
  const endStrings = endDate.split('-');
  const newEndDate = new Date(`${endStrings[0]}-${endStrings[1]}`).toISOString();

  const completedDates = [];
  filteredDates.forEach((date, index) => {
    // Add start date if it's not one of the existing dates
    if (index === 0 && date !== newStartDate) {
      completedDates.push(newStartDate);
    }
    completedDates.push(date);
    // Add end date if it's not one of the existing dates
    if (filteredDates.length === index + 1 && date !== newEndDate) {
      completedDates.push(newEndDate);
    }
  });
  return completedDates;
};

export const getFullSetOfDates = (partialMonths: string[]): string[] => {
  const completeMonths = [];
  // Assuming partialMonths is sorted, convert the month number to a date-fns month, e.g. 0 for January, 1 for February, etc
  const firstMonthDate = subMonths(partialMonths[0], 1);
  const lastMonthDate = subMonths(partialMonths[partialMonths.length - 1], 1);
  const numberOfMonths = differenceInMonths(lastMonthDate, firstMonthDate);
  let currentMonthDate = firstMonthDate;
  for (let i = 0; i <= numberOfMonths; i = i + 1) {
    currentMonthDate = addMonths(currentMonthDate, 1);
    completeMonths.push(format(currentMonthDate, 'yyyy-MM-dd'));
  }
  return completeMonths;
};

export const getValues = (data: Reason[], dates: string[]): number[][] => {
  // For each reason, we extract the value if it exists or we add 0 for missing data, to have 12 months of data
  return data.map(reason => {
    const newArray = [];
    dates.forEach(date => {
      const dataPoint = reason.data.find(object => object.date === date);
      newArray.push(dataPoint?.value || 0);
    });
    return newArray;
  });
};
