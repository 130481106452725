import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Common } from 'src/modules/community/communityMetrics/types';

export const initialState: Common = {
  currentTab: 'growth',
};

export const communityMetricsSlice = createSlice({
  name: 'communityMetrics',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
});

export const {
  setCurrentTab,
} = communityMetricsSlice.actions;

export default communityMetricsSlice.reducer;
